import axiosInstance from "../utils/axiosInstance";
import {actionCreator, createRequestTypes} from "../utils/helpers";



/* VERSION NEW */
export const LOAD_EVENTS_IN_PROGRESS = "LOAD_EVENTS_IN_PROGRESS";
export const loadEventsInProgress = () => ({
    type: LOAD_EVENTS_IN_PROGRESS
});


export const LOAD_EVENTS_FAILURE = "LOAD_EVENTS_FAILURE";
export const loadEventsFailure = () => ({
    type: LOAD_EVENTS_FAILURE
});


export const LOAD_EVENTS_UPCOMING_SUCCESS = "LOAD_EVENTS_UPCOMING_SUCCESS";
export const loadEventsUpcomingSuccess = (events) => ({
    type: LOAD_EVENTS_UPCOMING_SUCCESS,
    payload: { events }
});


export const LOAD_EVENTS_BY_MONTH_SUCCESS = "LOAD_EVENTS_BY_MONTH_SUCCESS";
export const loadEventsByMonthSuccess = (events, month, year) => ({
    type: LOAD_EVENTS_BY_MONTH_SUCCESS,
    payload: { 
        month: month,
        year: year,
        data: events 
    }
});


/* VERSION OLD */
export const GET_EVENT_BY_ID = createRequestTypes('GET_EVENT_BY_ID');
export const CREATE_EVENT = createRequestTypes('CREATE_EVENT');

export const getEventById = (id) => async (dispatch) => {
    dispatch(actionCreator.request(GET_EVENT_BY_ID));

    const response = await axiosInstance.get(`/events/event-id/${id}`);
    return response; //this is not right
}

export const createEvent = () => async (dispatch) => {
    //todo
}