export const LOAD_MAIN_IN_PROGRESS = "LOAD_MAIN_IN_PROGRESS";
export const loadMainInProgress = () => ({
    type: LOAD_MAIN_IN_PROGRESS
});

export const LOAD_MAIN_FAILURE = "LOAD_MAIN_FAILURE";
export const loadMainFailure = () => ({
    type: LOAD_MAIN_FAILURE
});

export const LOAD_MAIN_BY_MONTH_SUCCESS = "LOAD_MAIN_BY_MONTH";
export const loadMainByMonthSuccess = (main, month, year) => ({
    type: LOAD_MAIN_BY_MONTH_SUCCESS,
    payload: {
        month: month,
        year: year,
        data: main
    }
});