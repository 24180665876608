import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import AdminLogin from './containers/User/AdminLogin';
import MainLayout from "./containers/Layout/MainLayout";

import './resources/styles/index.scss';

class App extends Component {
    render() {
        return (
            <>
                <Switch>
                    <Route path="/admin-login" render={props => <AdminLogin {...props} />}/>
                    <Route path="/" render={props => <MainLayout {...props} />}/>
                </Switch>
            </>
        )
    }
}

export default withRouter(App);