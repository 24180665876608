const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
const EMPTY = 'EMPTY';
const CLEAR = 'CLEAR';
const MESSAGE = 'MESSAGE';

export const action = (type, payload = {}) => {
	return { type, ...payload };
};

export const verboseAction = (type, payload = {}) => {
	return {
		type: type,
		payload: { ...payload }
	};
};

export const createRequestTypes = (base) => {
	return [ REQUEST, SUCCESS, FAILURE, CLEAR, EMPTY, MESSAGE ].reduce((acc, type) => {
		acc[type] = `${base}_${type}`;
		return acc;
	}, {});
};

export const actionCreator = {
	request: (type) => action(type.REQUEST),
	success: (type, payload) => action(type.SUCCESS, { payload }),
	failure: (type, error) => verboseAction(type.FAILURE, error),
	empty: (type) => action(type.EMPTY),
	clear: (type) => action(type.CLEAR),
	message: (type, payload) => action(type.MESSAGE, { payload }),
};

export const changeChronosTheme = () => {
	const currentTheme = localStorage.getItem("chronosTheme") ? localStorage.getItem("chronosTheme") : "light";
	const newTheme = (currentTheme === "light" ? "dark" : "light");
	localStorage.setItem("chronosTheme", newTheme);
	return newTheme;
}


export const eventCategories = [
    {
        name: "technology",
        text: "Технологии"
    },
    {
        name: "health",
        text: "Здраве"
    },
    {
        name: "science",
        text: "Наука"
    },
    {
        name: "business",
        text: "Бизнес"
    },
    {
        name: "sport",
        text: "Спорт"
    },
    {
        name: "culture",
        text: "Култура"
    },
    {
        name: "ecology",
        text: "Екология"
    },
    {
        name: "fun",
        text: "Развлечение"
    }
]

export const yearHolidays = {
    y2024: {
        m1: {
            d1: "Нова година"
        },
        m3: {
            d3: "145 години от Освобождението на България от османско робство",
            d4: "Почивен (3 март)"
        },
        m5: {
            d1: "Ден на труда",
            d3: "Разпети Петък",
            d4: "Велика Събота",
            d5: "Великден (Възкресение Христово)",
            d6: "Гергьовден, Ден на храбростта и празник на Българската армия",
            d24: "Ден на светите братя Кирил и Методий, на българската азбука, просвета и култура и на славянската книжовност"
        },
        m9: {
            d6: "Съединението на България",
            d22: "Ден на независимостта на България",
            d23: "Почивен (22 септември)"
        },
        m12: {
            d24: "Коледа",
            d25: "Коледа",
            d26: "Коледа",
        }
    }
};

export const seasonNow = () => {
    const dateNow = new Date();

    let time = {
        year: dateNow.getFullYear(), 
        month: dateNow.getMonth() + 1,
        date: dateNow.getDate()
    };

    const seasons2022 = {
        spring: {
            date: 20, //legit till 2027
            month: 3  // 1
        },
        summer: {
            date: 20, //legit 2024
            month: 6  // 2
        },
        autumn: {
            date: 22, //legit 2024
            month: 9  // 3
        },
        winter: {
            date: 21, //legit 2024
            month: 12  // 4
        }
    };

    const allSeasons = ["spring", "summer", "autumn", "winter"];

    let season;
    if (time.month === 1 || time.month === 2) {
        season = 4;
    } else if (time.month === 3) {
        if (time.date < seasons2022.spring.date) {
            season = 4;
        } else if(time.date > seasons2022.spring.date) {
            season = 1;
        }
    } else if (time.month === 4 || time.month === 5) {
        season = 1;
    } else if (time.month === 6) {
        if (time.date < seasons2022.summer.date) {
            season = 1;
        } else if(time.date > seasons2022.summer.date) {
            season = 2;
        }
    } else if (time.month === 7 || time.month === 8) {
        season = 2;
    } else if (time.month === 9) {
        if (time.date < seasons2022.autumn.date) {
            season = 2;
        } else if(time.date > seasons2022.autumn.date) {
            season = 3;
        }
    } else if (time.month === 10 || time.month === 11) {
        season = 3;
    } else if (time.month === 12) {
        if (time.date < seasons2022.winter.date) {
            season = 3;
        } else if(time.date > seasons2022.winter.date) {
            season = 4;
        }
    } else {
        // ERROR SOMETHING IS WRONG
    }

    return allSeasons[season - 1];
}

export const weekDays = ["неделя", "понеделник", "вторник", "сряда", "четвъртък", "петък", "събота"];

export const weekDaysShort = ["Нед", "Пон", "Вто", "Сря", "Чет", "Пет", "Съб"];

export const monthNames = [ "Януари", "Февруари", "Март", "Април", "Май", "Юни", "Юли", "Август", "Септември", "Октомври", "Ноември", "Декември" ];

export const monthNamesShort = ["яну", "фев", "мар", "апр", "май", "юни", "юли", "авг", "сеп", "окт", "ное", "дек"];

export const regexMail = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);