import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import { loadEventsByMonth, loadMainByMonth } from '../../thunks';
import AstroBox from '../layout/AstroBox';
import MoonState from '../layout/MoonState';
import ZodiacSign from '../layout/ZodiacSign';
import SeasonsState from '../layout/SeasonsState';
import CalendarItem from '../../containers/MainApp/Calendar/CalendarItem/CalendarItem';
import SectionYearHolidays from './SectionYearHolidays';
import { yearHolidays, weekDays, monthNames } from '../../utils/helpers';
import SidebarEvents from '../layout/SidebarEvents';
import DateBox from '../layout/DateBox';
import TodayInfo from '../layout/TodayInfo';

class SectionIntro extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isActiveHolidaysPopup: false,
            holidaysPopupActiveYear: 2024
        }
    }

    componentDidMount() {
        const dateNow = new Date();

        let time = {
            year: dateNow.getFullYear(), 
            month: dateNow.getMonth() + 1,
            date: dateNow.getDate()
        }

        if (this.props.eventsByMonth.length === 0 || !this.props.eventsByMonth.some((monthItem) => (monthItem.month === time.month && monthItem.year === time.year))) {
            this.props.startLoadingEventsByMonth({year: time.year, month: time.month});
        }

        if (!this.props.mainByMonth.some((monthItem) => (monthItem.month === time.month && monthItem.year === time.year))) {
            this.props.startLoadingMainByMonth({year: time.year, month: time.month});
        }
    }

    // secondsToHms = (d) => {
    //     d = Number(d);
    //     const h = Math.floor(d / 3600);
    //     const m = Math.floor(d % 3600 / 60);
    //     const s = Math.floor((d % 3600) % 60);
    
    //     const hDisplay = h > 0 ? h + ":" : "";
    //     const mDisplay = m > 0 ? m + ":" : "";
    //     const sDisplay = s > 0 ? s : "";
        
    //     return hDisplay + mDisplay + sDisplay; 
    // }

    showYearHolidaysPopup = () => {
        this.setState({
            isActiveHolidaysPopup: true
        })
    }

    closeYearHolidaysPopup = () => {
        this.setState({
            isActiveHolidaysPopup: false
        });
    }

    render() {
        const today = new Date();

        const time = {
            year: today.getFullYear(),
            month: today.getMonth() + 1  
        }

        const thisMonthMainInfo = this.props.mainByMonth.find((elem) => (elem.month === time.month && elem.year === time.year));
        const thisDayMainInfo = (thisMonthMainInfo && thisMonthMainInfo.data && thisMonthMainInfo.data[0]) ? thisMonthMainInfo.data[0].info[today.getDate() - 1] : {};

        return (
            <section id="section-intro">
                <div className="intro-content">
                    <div className="intro-item intro-calendar">
                        <div className="intro-calendar-head">
                            {/* <h3>Днес</h3> */}
                            <h3>Подготвен за твоя ден!</h3>
                        </div>
                        <div className="intro-calendar-boxes">
                            <div className="main-box intro-box-today">
                                <div className="box-title">
                                    <h3>Днес</h3>
                                </div>
                                <DateBox weekDayText={weekDays[today.getDay()]} dateText={today.getDate() + " " + (monthNames[today.getMonth()])} />
                                <TodayInfo dayInfo={thisDayMainInfo} />
                            </div>
                            <div className="main-box intro-box-astro">
                                <div className='box-astro-head'>
                                    <div className="box-title">
                                        <h3>Астро</h3>
                                    </div>
                                    <DateBox weekDayText={weekDays[today.getDay()]} dateText={today.getDate() + " " + (monthNames[today.getMonth()])} />
                                    <div className='box-astro-widgets'>
                                        {(thisDayMainInfo?.moon_phase?.length > 0) &&
                                            <MoonState moon={thisDayMainInfo.moon_phase} />}
                                        <ZodiacSign date={today.getDate()} month={today.getMonth()} />
                                        <SeasonsState />
                                    </div>
                                </div>
                                <TodayInfo dayInfo={thisDayMainInfo} />
                                <AstroBox />
                                <div className="actions-wrap">
                                    <Link to="/calendar" className="main-button cal-button">
                                        Актуален календар
                                    </Link>
                                    <button 
                                        className="main-button sub-button" 
                                        onClick={this.showYearHolidaysPopup}>
                                            <span>Почивни дни </span> 
                                            <span>{this.state.holidaysPopupActiveYear}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="main-box intro-box-calendar">
                            <div className="box-calendar-head">
                                <div className="box-title">
                                    <h3>{monthNames[time.month - 1] + " " + time.year}</h3>
                                </div>
                                <div className="box-calendar-button">
                                    <button className="main-button sub-button" onClick={this.showYearHolidaysPopup}>
                                        <span>Почивни дни </span>
                                        <span>{this.state.holidaysPopupActiveYear}</span>
                                    </button>
                                </div>
                            </div>
                            <div className="intro-calendar-content">
                                <CalendarItem time={time} onSelectDate={today.getDate()} isFirst={true} />
                            </div>
                        </div>
                    </div>
                    <div className="intro-item intro-events">
                        <div className="main-box intro-box-events">
                            <div className="intro-events-content">
                                <SidebarEvents eventInfo={{category: "all"}} eventsCount={4} />
                            </div>
                            <div className="intro-to-events">
                                <Link to="/events" className="main-button">Всички събития</Link>
                            </div>
                        </div>
                        <div className="main-box intro-box-tools">
                            <div className="box-title">
                                <h3>Още събития?</h3>
                            </div>
                            <p>Ако си организатор на събития или знаеш за източник, който не присъства на нашата платформа, пиши ни.</p>
                            <div className="actions-wrap">
                                <Link to="/contacts" className="main-button">Контакти</Link>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.isActiveHolidaysPopup ? (
                    <SectionYearHolidays closeYearHolidaysPopup={this.closeYearHolidaysPopup} year={this.state.holidaysPopupActiveYear} holidays={yearHolidays["y" + this.state.holidaysPopupActiveYear]} />
                ) : ""}
            </section>
        )
    }
}

const mapStateToProps = state => ({
    eventsByMonth: state.eventsByMonth,
    mainByMonth: state.mainByMonth
});

const mapDispatchToProps = dispatch => ({
    startLoadingEventsByMonth: (time) => dispatch(loadEventsByMonth(time)),
    startLoadingMainByMonth: (time) => dispatch(loadMainByMonth(time))
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionIntro);