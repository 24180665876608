import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {loadEventsUpcoming} from '../../thunks';
import Loader from '../../containers/MainApp/Components/Loader';

class SidebarEvents extends Component {
    componentDidMount() {
        if (Object.keys(this.props.eventsUpcoming).length === 0) {
            this.props.startLoadingEventsUpcoming();
        }
    }

    render() {
        const eventsCount = this.props.eventsCount ? this.props.eventsCount : 5;
        
        const eventsContent = (this.props.eventsUpcoming.events && !this.props.eventsUpcoming.events.error) ? (
            (this.props.eventInfo && this.props.eventInfo.category !== "all") ? 
                this.props.eventsUpcoming.events.filter(el => el.category === this.props.eventInfo.category) : 
                this.props.eventsUpcoming.events
        ) : false;
        
        const categoryTitle = (this.props.eventType && this.props.eventType.length > 0) ? ("Събития - " + this.props.eventType) : "Събития";

        return (
            <div className="sidebar-content">
                <div className="box-title">
                    <h3>{categoryTitle}</h3>
                </div>
                <div className="sidebar-events-wrap">
                    { this.props.isLoading ? (
                        <Loader />
                    ) : (
                        (eventsContent !== false && eventsContent.length > 0) ? (
                            <div className="sidebar-events">
                                { eventsContent.slice(0, eventsCount).map((event, key) => (
                                    <Link to={"/event/" + event._id}  className="sidebar-event-item" key={key}>
                                        <div className="sidebar-event-image">
                                            <span style={{ backgroundImage: "url(" + event.media + ")" }} className="event-image"></span>
                                        </div>
                                        <div className="sidebar-event-body">
                                            <h3>{ event.name }</h3>
                                        </div>
                                    </Link>
                                )) }
                            </div>
                        ) : (
                            <div className="sidebar-error">Има проблем при свързването с базата.</div>
                        )
                    ) }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    eventsUpcoming: state.eventsUpcoming,
    isLoading: state.isLoading
});

const mapDispatchToProps = dispatch => ({
    startLoadingEventsUpcoming: () => dispatch(loadEventsUpcoming())
});


export default connect(mapStateToProps, mapDispatchToProps)(SidebarEvents);