import React from 'react';

import { connect } from 'react-redux';
import { createEvent } from '../../actions/events.actions';
import { eventCategories } from '../../utils/helpers';

const EventForm = ({onCreatePressed}) => (
    <div className="add-event-form-wrap">
        <form>
            <div className="add-event-form-row">
                <div className="add-event-form-item">
                    <label htmlFor="add-event-form-name">ИМЕ <span>*</span></label>
                    <input type="text" className="main-input" id="add-event-form-name" />
                </div>
            </div>

            <div className="add-event-form-row">
                <div className="add-event-form-item">
                    <label htmlFor="add-event-form-descrition">ОПИСАНИЕ <span>*</span></label>
                    <textarea id="add-event-form-description"></textarea>
                </div>
            </div>

            <div className="add-event-form-row">
                <div className="add-event-form-item add-event-form-col">
                    <label htmlFor="add-event-form-date">ДАТА <span>*</span></label>
                    <input type="text" className="main-input" id="add-event-form-date" />
                </div>
                
                <div className="add-event-form-item add-event-form-col">
                    <label htmlFor="add-event-form-time">ЧАС <span>*</span></label>
                    <input type="text" className="main-input" id="add-event-form-time" />
                </div>
            </div>

            <div className="add-event-form-row">
                <div className="add-event-form-item add-event-form-col">
                    <label htmlFor="add-event-form-city">ГРАД/СЕЛО <span>*</span></label>
                    <input type="text" className="main-input" id="add-event-form-city" />
                </div>

                <div className="add-event-form-item add-event-form-col">
                    <label htmlFor="add-event-form-place">ЛОКАЦИЯ/АДРЕС</label>
                    <input type="text" className="main-input" id="add-event-form-place" />
                </div>
            </div>

            <div className="add-event-form-row">
                <div className="add-event-form-item">
                    <label htmlFor="add-event-form-category">КАТЕГОРИЯ <span>*</span></label>
                    <select id="add-event-form-category">
                        {eventCategories.map(item => (
                            <option value={item.name}>{item.text}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="add-event-form-row">
                <div className="add-event-form-item add-event-form-col">
                    <label htmlFor="add-event-form-source">ЛИНК КЪМ СЪБИТИЕТО / ИЗТОЧНИК <span>*</span></label>
                    <input type="text" className="main-input" id="add-event-form-source" />
                </div>
                
                <div className="add-event-form-item add-event-form-col">
                    <label htmlFor="add-event-form-author">ОРГАНИЗАТОР <span>*</span></label>
                    <input type="text" className="main-input" id="add-event-form-author" />
                </div>
            </div>

            <div className="add-event-form-row">
                <div className="add-event-form-item">
                    media
                </div>
            </div>

            <div className="add-event-form-row">
                <div className="add-event-form-item">
                    <label htmlFor="add-event-form-name">КАРТА - ЛИНК КЪМ GOOGLE MAPS</label>
                    <input type="text" className="main-input" id="add-event-form-name" />
                </div>
            </div>
            
            <div className="add-event-form-row">
                <div className="add-event-form-item">
                    <button 
                        onClick={() => {
                            onCreatePressed()
                        }}
                        className="main-button">СЪЗДАЙ/РЕДАКТИРАЙ</button>
                </div>
            </div>
        </form>
    </div>
);

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    onCreatePressed: event => dispatch(createEvent(event))
});

export default connect (mapStateToProps, mapDispatchToProps)(EventForm);