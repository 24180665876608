import React, {Component} from 'react';

class CalendarItemDayInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {isActiveDayInfoPopup: false};
    }

    // openDayInfoPopup = () => {
    //     this.setState({isActiveDayInfoPopup: true});
    // }

    // closeDayInfoPopup = () => {
    //     this.setState({isActiveDayInfoPopup: false});
    // }

    // handleClickOutside = (event) => {
    //     if (event.target && event.target.className.includes("popup-day-info")) {
    //         this.closeDayInfoPopup();
    //     }
    // }

    generateDayInfo = () => {
        let res = [];

        let moonPosText = "";
        let moonPos = "";
        let moonTime = "";

        /* --- OFFICIAL HOLIDAY AND CELEBRATIONS --- */
        if (this.props.dateInfo.hasOwnProperty("official_holiday")) {
            let isOffDayVisible = sessionStorage.getItem("isActiveHolidayState") === "0" ? false : true;
            let offDayClass = "cal-date-off-holiday" + (!isOffDayVisible ? " hideInstrument" : "");

            res.push(
                <div className={ offDayClass } key={ "holiday-" + this.props.today[0] + "-" + this.props.today[1] + "-" + this.props.today[2] }>
                    <span className="icon icon-holiday" title={ this.props.dateInfo?.official_holiday }></span>
                </div>
            );
        } else if (this.props.dateInfo.hasOwnProperty("celeb_day")) {
            let isCelebDayVisible = sessionStorage.getItem("isActiveCelebrationState") === "0" ? false : true;
            let celebDayClass = "cal-date-celeb-day" + (!isCelebDayVisible ? " hideInstrument" : "");

            res.push(
                <div className={ celebDayClass } key={ "celeb-" + this.props.today[0] + "-" + this.props.today[1] + "-" + this.props.today[2] }>
                    <span className="icon icon-info" title={ this.props.dateInfo?.celeb_day }></span>
                </div>
            );
        } else {
            res.push(<div key={"empty-celeb-" + this.props.today[0] + "-" + this.props.today[1] + "-" + this.props.today[2]}></div>);
        }

        /* --- MOON PHASE --- */
        if (this.props.dateInfo.hasOwnProperty("moon_phase")) {
            const moonAra = this.props.dateInfo.moon_phase.split("-");
            const moph = moonAra[0];
            moonTime = (moonAra[1] !== undefined) ? ("- " + moonAra[1]) : "";
            let isMoonVisible = sessionStorage.getItem("isActiveMoonState") === "0" ? false : true;

            moonPos = (moph === "full_moon") ? "full-moon" : 
                            (moph === "new_moon") ? "new-moon" : 
                            (moph === "first_quarter") ? "first-quarter" : 
                            (moph === "last_quarter") ? "last-quarter" : "";

            moonPosText = (moph === "full_moon") ? "пълнолуние" : 
                                (moph === "new_moon") ? "новолуние" : 
                                (moph === "first_quarter") ? "първа четвъртина" : 
                                (moph === "last_quarter") ? "последна четвъртина" : "";
        
            let moonElem = (
                <div className={ "cal-date-moon-phase" + (!isMoonVisible ? " hideInstrument" : "") } 
                     key={"moon-" + this.props.today[0] + "-" + this.props.today[1] + "-" + this.props.today[2]}>
                    <span className={ "icon icon-moon moon-" + moonPos } title={ moonPosText }></span>
                </div>
            );

            res.push(moonElem);
        } else {
            res.push(<div key={"empty-moon-" + this.props.today[0] + "-" + this.props.today[1] + "-" + this.props.today[2]}></div>);
        }

        return res;
    }

    //moonPos, moonPosText, moonTime
    render() {
        return <div className="cal-date-info-wrap">
            {/* <div className="cal-date-info" onClick={this.openDayInfoPopup}> */}
            
            <div className="cal-date-info">
                { this.generateDayInfo() }
            </div>
            
            {/* this.state.isActiveDayInfoPopup ? (
                <div className="popup-wrap popup-day-info" onClick={this.handleClickOutside}>
                    <div className="main-box popup-content">
                        <div className="popup-title">
                            <h4>{ ("0" + this.props.today[0]).slice(-2) + "." + ("0" + this.props.today[1]).slice(-2) + "." + this.props.today[2] }</h4>
                        </div>

                        { this.props.dateInfo.hasOwnProperty("official_holiday") ? (
                            <div className="popup-day-info-row">
                                <div className="popup-icon-holder">
                                    <span className="icon icon-holiday" title={ this.props.dateInfo?.official_holiday }></span>
                                </div>
                                <p>Официален празник: { this.props.dateInfo?.official_holiday }</p>
                            </div>
                        ) : "" }
                        { this.props.dateInfo.hasOwnProperty("celeb_day") ? (
                            <div className="popup-day-info-row">
                                <div className="popup-icon-holder">
                                    <span className="icon icon-info" title={ this.props.dateInfo?.celeb_day }></span>
                                </div>
                                <p>{ this.props.dateInfo?.celeb_day }</p>
                            </div>
                        ) : "" }
                        { this.props.dateInfo.hasOwnProperty("name_day") ? (
                            <div className="popup-day-info-row">
                                <div className="popup-icon-holder">
                                    <span className="icon icon-celebration" title={ this.props.dateInfo.name_day }></span>
                                </div>
                                <p>{ this.props.dateInfo.name_day }</p>
                            </div>
                        ) : "" }
                        { moonPos !== "" ? (
                            <div className="popup-day-info-row">
                                <div className="popup-icon-holder">
                                    <span className={ "icon icon-moon moon-" + moonPos } title={ moonPosText }></span>
                                </div>
                                <p>{ "Фаза на луната - " + moonPosText + " " + moonTime}</p>
                            </div>
                        ) : "" }

                        <div className="close-button">
                            <button className="close-btn" onClick={this.closeDayInfoPopup}> 
                                <div className="close-btn-arrow-left"></div>
                                <div className="close-btn-arrow-right"></div>
                            </button>
                        </div>
                    </div>
                </div>
            ) : "" */}
        </div>
    }
}

export default CalendarItemDayInfo;