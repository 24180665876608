import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenedHamburger: false
        }
    }

    componentDidMount() {
        document.addEventListener("scroll", this.onScrollHandleHeader, true);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScrollHandleHeader);
    }

    onScrollHandleHeader = (e) => {
        let st = window.pageYOffset || e.srcElement.scrollTop;
        
        if (st > 50) {
            document.getElementById("site-header").classList.add('header-mini');
        } else {
            document.getElementById("site-header").classList.remove('header-mini');
        }
    }

    toggleHam = () => {
        this.setState(prevState => ({
            isOpenedHamburger: !prevState.isOpenedHamburger
        }));
    }

    render() {
        let menuOptions = [
            {
                text: "НАЧАЛО",
                href: "/home",
                isActive: false
            },
            {
                text: "КАЛЕНДАР",
                href: "/calendar",
                isActive: false
            },
            {
                text: "СЪБИТИЯ",
                href: "/events",
                isActive: false
            },
            {
                text: "КОНТАКТИ",
                href: "/contacts",
                isActive: false
            }
        ];

        menuOptions.forEach((item, i) => {
            if (this.props.path.includes(item.href) || this.props.path.includes(item.href.substring(0, item.href.length - 1))) {
                menuOptions[i].isActive = true;
            }
        });

        return (
            <header id="site-header" className={(this.props.path.includes("/home") ? "home-header" : "")}>
                <div className="header-wrap">
                    <div className="header-inner">
                        <div className="header-item h-logo">
                            <Link to="/">
                                <div id="main-logo">Chronos</div>
                            </Link>
                            <div className="beta-text">beta</div>
                        </div>
                        <nav className={"header-item main-menu" + (this.state.isOpenedHamburger ? " menu-active" : "")}>
                            <div className="menu-hamburger" onClick={ this.toggleHam }>
                                <div className="ham-arrows ham-arrow-1"></div>
                                <div className="ham-arrows ham-arrow-2"></div>
                                <div className="ham-arrows ham-arrow-3"></div>
                            </div>
                            <ul className="menu-options">
                                {menuOptions.map((menuItem, key) => (
                                    <li className="option-m" key={key}>
                                        <span className={"icon icon-small icon-" + menuItem.href.substring(1)}></span>
                                        <HashLink className={"page-link om-text" + (menuItem.isActive ? " om-active" : "")} to={ menuItem.href }>{ menuItem.text }</HashLink>
                                    </li>
                                ))}
                                <li className="option-m option-mobile-theme">
                                    <label className="theme-switcher">
                                        <input type="checkbox" defaultChecked={(this.props.theme === "dark")} onClick={() => this.props.changeTheme()} />
                                        <span className="theme-slider icon-theme"></span>
                                    </label>
                                    <span className="theme-text">Тема</span>
                                </li>
                            </ul>
                        </nav>
                        <div className="header-item h-user">
                            <div className="h-user-theme">
                                <span className="theme-text">Тема</span>
                                <label className="theme-switcher">
                                    <input type="checkbox" defaultChecked={(this.props.theme === "dark")} onClick={() => this.props.changeTheme()} />
                                    <span className="theme-slider icon-theme"></span>
                                </label>
                            </div>
                            <Link className="main-button h-login-btn page-link" to="/users/login">
                                <span className={"h-login-text" + (this.props.path.includes("/users/login") ? " om-active" : "")}>Вход</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header;