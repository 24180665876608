import {API_ROOT} from './utils/api-config';

import { 
    loadEventsUpcomingSuccess, 
    loadEventsFailure, 
    loadEventsInProgress, 
    loadEventsByMonthSuccess
} from "./actions/events.actions";

import { 
    loadMainFailure, 
    loadMainInProgress ,
    loadMainByMonthSuccess
} from './actions/dateInfo.actions';

export const loadEventsUpcoming = () => async (dispatch) => {
    try {
        dispatch(loadEventsInProgress()); //TO FINISH
        const response = await fetch(API_ROOT + '/events/upcoming-events');
        const events = await response.json();

        dispatch(loadEventsUpcomingSuccess(events));
    } catch(e) {
        dispatch(loadEventsFailure()); //TO FINISH
    }
}

export const loadEventsByMonth = (time) => async (dispatch) => {
    try {
        dispatch(loadEventsInProgress()); //TO FINISH
        const response = await fetch(`${API_ROOT}/events/month-events/${time.year}-${time.month}`);
        const events = await response.json();

        dispatch(loadEventsByMonthSuccess(events, time.month, time.year));
    } catch(e) {
        dispatch(loadEventsFailure()); //TO FINISH
    }
}

export const loadMainByMonth = (time) => async (dispatch) => {
    try {
        dispatch(loadMainInProgress()); //TO FINISH
        const response = await fetch(`${API_ROOT}/main-day-info/month-info/${time.year}-${time.month}`);
        const main = await response.json();
    
        dispatch(loadMainByMonthSuccess(main, time.month, time.year));
    } catch(e) {
        dispatch(loadMainFailure()); //TO FINISH
    }
}