import {
    LOAD_EVENTS_IN_PROGRESS,
    LOAD_EVENTS_FAILURE,
    LOAD_EVENTS_UPCOMING_SUCCESS,
    LOAD_EVENTS_BY_MONTH_SUCCESS
} from "../actions/events.actions";

export const eventsByMonthReducer = (state = [], action) => {
    const {type, payload} = action;

    switch (type) {
        case LOAD_EVENTS_BY_MONTH_SUCCESS: {
            return state.concat(payload);
        }
        case LOAD_EVENTS_IN_PROGRESS:
        case LOAD_EVENTS_FAILURE:

        // case GET_EVENT_BY_ID.SUCCESS:
        //     return {
        //         ...state,
        //         events: payload.events
        //     }
        // case CREATE_EVENT:
        //     return {
        //         ...state,

        //     }
        default:
            return state;
    }
}

export const eventsUpcomingReducer = (state = [], action) => {
    const {type, payload} = action;

    switch (type) {
        case LOAD_EVENTS_UPCOMING_SUCCESS: {
            const { events } = payload;
            return {
                ...state,
                events
            }
        }
        case LOAD_EVENTS_IN_PROGRESS:
        case LOAD_EVENTS_FAILURE:
            return {
                ...state
            };
        default:
            return state;
    }
}