import React, {Component} from 'react';
import {connect} from 'react-redux';

import CalendarItemDaysTitle from "./CalendarItemDaysTitle";
import Loader from '../../Components/Loader';
import CalendarItemEvent from "./CalendarItemEvent";
import CalendarItemDayInfo from './CalendarItemDayInfo';
import DayInfo from '../DayInfo';

class CalendarItemMonth extends Component {
    state = {
        isMonthLoaded: false,
        isActiveDatePopup: false,
        activeDate: "",
    }

    componentDidMount() {
        this.grid = this.generateGrid();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mainByMonth !== this.props.mainByMonth) {
            this.grid = this.generateGrid();
        }
    }

    openDatePopup = (e) => {
        console.log(e.target);
        if (e.target.classList.contains("cal-ib-row-date") && !e.target.classList.contains("cal-date-inactive")) {
            this.setState({
                isActiveDatePopup: true,
                activeDate: e.target.querySelector(".cal-text-date").innerText, // TOCHECK - fix this
            });
        }
    }

    closeDatePopup = () => {
        this.setState({isActiveDatePopup: false});
    }

    handleClickOutside = (event) => {
        if (event.target && event.target.className.includes("popup-date-info")) {
            this.closeDatePopup();
        }
    }

    generateGrid = () => {
        const monthEvents = this.props.eventsByMonth
            .find((monthData) => monthData.month === this.props.time.month && monthData.year === this.props.time.year);
        const mainByMonth = this.props.mainByMonth
            .find((monthData) => monthData.month === this.props.time.month && monthData.year === this.props.time.year);

        let gridRows = [];
        let gridCols = [];
        const timeNow = new Date();
        const currentYear = this.props.time.year;
        const currentMonth = this.props.time.month;
        let currentDate = 0;
        let prevDate = 0;

        /* ===== GRID TITLE ===== */
        gridRows.push(<CalendarItemDaysTitle key={"month-head"} />);

        /* ===== GRID BODY ===== */
        let firstDate = new Date(this.props.time.year, this.props.time.month - 1, 1).getDay();
        let lastDate = new Date(this.props.time.year, this.props.time.month, 0).getDay();
        const lastOfMonth = new Date(this.props.time.year, this.props.time.month, 0).getDate();
        const monthWeeks =  Math.ceil(((firstDate + 6) % 7 + lastOfMonth) / 7);

        if (firstDate === 0) {
            firstDate = 7;
        }

        let eventsInfo, holidayInfo;
        let calendarDateStatus;
        let dateInfo = {
            events: [],
            main: []
        };

        for (let j = 1; j <= monthWeeks; j++) {
            gridCols = [];

            for (let n = 1; n <= 7; n++) {
                eventsInfo = [];
                holidayInfo = [];
                calendarDateStatus = "";

                if ((j === 1 && firstDate !== 0 && n < firstDate) || (j === monthWeeks && lastDate !== 0 && n > lastDate)) {
                    calendarDateStatus += " cal-date-inactive";
                } else {
                    currentDate++;

                    /* ===== Weekend ===== */
                    if (n === 6 || n === 7) {
                        calendarDateStatus += " cal-date-weekend";
                    }

                    /* ===== Today ===== */
                    if (currentDate === timeNow.getDate() && (this.props.time.month - 1) === timeNow.getMonth() && (this.props.time.year === timeNow.getFullYear())) {
                        calendarDateStatus += " cal-date-today"; //TOCHECK - if used at all / remove if not
                    }

                    /* ===== Day info - celebrations / holidays / moon ===== */
                    if (mainByMonth?.data[0]?.info?.length > 0) {
                        for (let i = 0; i < mainByMonth.data[0].info.length; i++) { //TOCHECK - optimize
                            if (currentDate === mainByMonth.data[0].info[i].date) {
                                dateInfo.main = mainByMonth.data[0].info[i];
                            }
                        }
                    }

                    if (dateInfo.hasOwnProperty("main")) {
                        holidayInfo.push(
                            <CalendarItemDayInfo 
                                dateInfo={ dateInfo.main }
                                today={ [currentDate, currentMonth, currentYear] }
                                key={ dateInfo.main }
                            />
                        );

                        if (dateInfo.main.hasOwnProperty("official_holiday")) {
                            calendarDateStatus += " cal-date-off-day";
                        }
                    }

                    /* ===== Events ===== */
                    if (monthEvents?.data?.length > 0) {
                        //TOCHECK - optimize with not so many iterations
                        dateInfo.events = monthEvents.data.filter((event) => (
                            new Date(event.fullDate).getDate() === currentDate 
                        ));
                    }
                    
                    if (dateInfo.hasOwnProperty("events") && dateInfo.events.length > 0) {
                        for (let i = 0; i < dateInfo.events.length; i++) {
                            eventsInfo.push(<CalendarItemEvent eventInfo={ dateInfo.events[i] } key={"ev" + i} />);
                        }
                    }

                }

                gridCols.push(
                    <div className={ "cal-ib-row-date" + calendarDateStatus } onClick={ this.openDatePopup } key={ "col-" + j + "-" + n }>
                        <div className="cal-date-head">
                            <span className="cal-text-date">{ currentDate !== prevDate ? currentDate : '' }</span>
                            { holidayInfo }
                        </div>
                        <div className="cal-date-content">
                            { eventsInfo }
                        </div>
                    </div>
                );
                
                dateInfo = {
                    events: [],
                    main: []
                };

                prevDate = currentDate;
            }


            gridRows.push(
                <div className="cal-grid-row" key={ "row-" + j }>
                    { gridCols }
                </div>
            );
        }

        this.setState({isMonthLoaded: true});
        return gridRows;
    }

    render() {
        return <>
            <div className="cal-item-body-grid">
                {
                    this.state.isMonthLoaded ? (
                        this.grid
                    ) : (
                        <Loader />
                    )
                }
            </div>
            { this.state.isActiveDatePopup ? (
                <div className="popup-wrap popup-date-info" onClick={this.handleClickOutside}>
                    <div className="main-box popup-content">

                        <DayInfo />

                        <div className="close-button">
                            <button className="close-btn" onClick={this.closeDatePopup}> 
                                <div className="close-btn-arrow-left"></div>
                                <div className="close-btn-arrow-right"></div>
                            </button>
                        </div>
                    </div>
                </div>
            ) : <></> }
        </>
    }
}

const mapStateToProps = state => ({
    eventsByMonth: state.eventsByMonth,
    mainByMonth: state.mainByMonth,
    isLoading: state.isLoading
});

export default connect(mapStateToProps)(CalendarItemMonth);