import React from 'react';

const Tooltip = (props) => {
    const geoText = props.isActiveGeolocation ? ( 
        <p>Геолокацията е включена. Можете да я <a href="https://support.google.com/chrome/answer/142065?hl=en&co=GENIE.Platform%3DDesktop" target="_blank" rel="noopener noreferrer">спрете</a> от вашите настройки.</p>
    ) : (
        <p>Геолокацията е изключена. Можете да я <a href="https://support.google.com/chrome/answer/142065?hl=en&co=GENIE.Platform%3DDesktop" target="_blank" rel="noopener noreferrer">пуснете</a> от вашите настройки.</p>
    );

    const closeTooltip = (e) => {
        e.stopPropagation();
        props.closeTooltip();
    }

    return <div className="tooltip-wrap">
        <div className="tooltip-content">
            <div className="tooltip-text">{geoText}</div>

            <div className="close-button">
                    <button className="close-btn" onClick={closeTooltip}>
                        <div className="close-btn-arrow-left"></div>
                        <div className="close-btn-arrow-right"></div>
                    </button>
                </div>
        </div>
    </div>
}

export default Tooltip;