import React, {Component} from 'react';

import EventForm from '../../components/admin-layout/EventForm';

class AddEventPage extends Component {
    render() {
        return (
            <div className="add-event-wrap">
                <div className="page-title">
                    <h1>ДОБАВИ СЪБИТИЕ</h1>
                </div>

                <div className="add-event-container">
                    <EventForm />
                </div>
            </div>
        )
    }
}

export default AddEventPage;