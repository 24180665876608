import React from 'react';

const CalendarItemDaysTitle = () => {
    const shortDays = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Нд"]; //TOCHECK - get days from helpers but check differences
    
    let titleGridCols = [];

    for (let i = 0; i < 7; i++) {
        titleGridCols.push(<div className={"cal-date-title" + ((i === 5 || i === 6) ? " cal-date-title-weekend" : "")} key={i}>{ shortDays[i] }</div>);
    }

    return <div className="cal-grid-row cal-grid-row-head">
        {titleGridCols}
    </div>
}

export default CalendarItemDaysTitle;