import {combineReducers} from "redux";

import { isLoading } from "./loading.reducer";
import {eventsByMonthReducer, eventsUpcomingReducer} from "./events.reducer";
import { mainByMonthReducer } from "./dateInfo.reducer";

const appReducer = combineReducers({
    isLoading: isLoading,
    eventsByMonth: eventsByMonthReducer,
    eventsUpcoming: eventsUpcomingReducer,
    mainByMonth: mainByMonthReducer
});

export const rootReducer = (state, action) => appReducer(state, action);