import React, {Component} from 'react';

class CalendarFullScreen extends Component {
    toggleFullScreen = () => {
        const calWrap = document.getElementById("calendar-wrap");
        const elem = document.getElementById("calendar-container");

        if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
            elem.classList.toggle("calendar-full-screen");
            if (elem.requestFullScreen) {
                calWrap.requestFullScreen();
            } else if (elem.mozRequestFullScreen) {
                calWrap.mozRequestFullScreen();
            } else if (elem.webkitRequestFullScreen) {
                calWrap.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            } else if (elem.msRequestFullscreen) {
                calWrap.msRequestFullscreen();
            } else {
                elem.classList.toggle("calendar-full-screen");
            }
        } else {
            elem.classList.toggle("calendar-full-screen");
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else {
                elem.classList.toggle("calendar-full-screen");
            }
        }
    }

    render() {
        return (
            <div className="calendar-full-screen-btn" onClick={this.toggleFullScreen}>
                <div className="cal-fs-elem fs-el-top-left"></div>
                <div className="cal-fs-elem fs-el-top-right"></div>
                <div className="cal-fs-elem fs-el-bottom-left"></div>
                <div className="cal-fs-elem fs-el-bottom-right"></div>
            </div>
        )
    } 
}    

export default CalendarFullScreen;