import React, {Component, useRef, useEffect} from 'react';

import { getSunrise, getSunset } from 'sunrise-sunset-js';
import Tooltip from '../layout/Tooltip';
import Loader from '../../containers/MainApp/Components/Loader';

export default class AstroBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dateSunrise: "",
            dateSunset: "",
            isActiveGeolocation: false,
            isActiveGeolocationTooltip: false,
            geolocationData: {}
        }
    }

    componentDidMount() {
        if ("geolocation" in navigator) {
            this.getLocation();
        } else {
            this.setState({
                isActiveGeolocation: false
            });
        }
    }

    setSunState = (position) => {
        let lat = 42.698334; //default sofia
        let lon = 23.319941; //default sofia

        if (position && position.coords) {
            lat = position.coords.latitude;
            lon = position.coords.longitude;
        }

        let dSunrise = getSunrise(lat, lon);
        let dSunset = getSunset(lat, lon);

        this.setState({
            dateSunrise: dSunrise.toLocaleString(),
            dateSunset: dSunset.toLocaleString()
        });
    }

    getLocation = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                this.setState({
                    isActiveGeolocation: true,
                    geolocationData: position
                });
                this.setSunState(position);
            },
            (error) => {
                this.setState({
                    isActiveGeolocation: false
                });
                this.setSunState(false);
                console.log(error.code + " - " + error.message);
            }
        );
    }

    showGeoStats = () => {
        this.setState({
            isActiveGeolocationTooltip: true
        });
    }

    closeTooltip = () => {
        this.setState({
            isActiveGeolocationTooltip: false
        });
    }

    render() {
        const geoPosition = this.state.isActiveGeolocation ? ({
            lat: this.state.geolocationData.coords.latitude,
            long: this.state.geolocationData.coords.longitude
        }) : {}

        const todaySunrise = new Date(this.state.dateSunrise);
        const todaySunset = new Date(this.state.dateSunset);

        const dayTime = (todaySunset - todaySunrise) / 1000;
        const daySeconds = 86400;

        const dayTimePerc = (dayTime / daySeconds) * 100;
        const nightTimePerc = 100 - dayTimePerc;

        return (
            <div className="astro-content">
                <div className="astro-left">
                    <div className="astro-graph-wrap">
                        <div className="astro-graph">
                            {!isNaN(dayTimePerc) ? (
                                <>
                                    <CanvasPie daytimeperc={dayTimePerc} nighttimeperc={nightTimePerc} />
                                    <div className="astro-graph-text">
                                        <p className="day">
                                            <span className="graph-bold">Ден</span>
                                            <span>{Math.round(dayTimePerc)}%</span>
                                        </p>
                                        <p className="night">
                                            <span className="graph-bold">Нощ</span>
                                            <span>{Math.round(nightTimePerc)}%</span>
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <Loader />
                            )}
                        </div>
                    </div>
                </div>
                <div className="astro-right">
                    <div className="astro-sun">
                        <p>
                            <span className="icon-sun icon-sunrise"></span>
                            <span className="astro-sun-text">Изгрев: </span>
                            <span className="astro-sun-hour">{("00" + todaySunrise.getHours()).slice(-2) + ":" + ("00" + todaySunrise.getMinutes()).slice(-2) + ":" + ("00" + todaySunrise.getSeconds()).slice(-2)}</span>
                        </p>
                        <p>
                            <span className="icon-sun icon-sunset"></span>
                            <span className="astro-sun-text">Залез: </span>
                            <span className="astro-sun-hour">{("00" + todaySunset.getHours()).slice(-2) + ":" + ("00" + todaySunset.getMinutes()).slice(-2) + ":" + ("00" + todaySunset.getSeconds()).slice(-2)}</span>
                        </p>
                    </div>
                    <p className="geo-active-text">Локация:</p>
                    <div className="astro-geolocation-btn main-button" onClick={this.showGeoStats}>
                        <div className={"astro-geolocation"}>
                            <div className="geo-stats" title="Геолокация">
                                <span className="icon icon-location"></span>
                            </div>
                            {this.state.isActiveGeolocationTooltip ? (
                                <Tooltip isActiveGeolocation={this.state.isActiveGeolocation} buttonText={"Включи"} tooltipClick={this.getLocation} closeTooltip={this.closeTooltip} />
                            ) : ""}
                        </div>
                        <span className="geolocation-text">
                            {this.state.isActiveGeolocation ? (
                                <>
                                    <p className="active-geo-text">Ш: {geoPosition.lat}</p>
                                    <p className="active-geo-text">Д: {geoPosition.long}</p>
                                </>
                            ) : (
                                "София"
                            )}
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

const CanvasPie = props => {
    const dayTimeAngle = props.daytimeperc / 50;
    const nightTimeAngle = props.nighttimeperc / 50;

    const canvasRef = useRef(null);

    const draw = ctx => {
        const colors = ['#0B1E38', '#DB901C'];
        const angles = [Math.PI * nightTimeAngle, Math.PI * dayTimeAngle];

        let beginAngle = 0;
        let endAngle = Math.PI * 1.5;

        for (let i = 0; i < angles.length; i = i + 1) {
            beginAngle = endAngle;
            endAngle = endAngle + angles[i];   // here should be the hour of sunrise
            
            ctx.beginPath();
            ctx.fillStyle = colors[i % colors.length];
            
            ctx.moveTo(72, 72);
            ctx.arc(72, 72, 72, beginAngle, endAngle);
            ctx.lineTo(72, 72);
            //ctx.stroke();
            
            ctx.fill();
        }
    }
    
    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
    
        draw(context);
    }, [draw]);
    
    return <canvas width="144px" height="144px" ref={canvasRef} {...props}/>
}