
import React, {Component} from 'react';

import { monthNames } from '../../utils/helpers';

class SectionYearHolidays extends Component {
    handleClickOutside = (event) => {
        if (event.target && event.target.className.includes("popup-year-holidays")) {
            this.closeHolidaysPopup();
        }
    }
    
    generateYear = (year) => {
        let table = [];

        for (let i = 1; i <= 12; i++) {
            table.push(this.generateMonth(i, year));
        }

        return table;
    }

    generateMonth = (month, year) => {
        let gridRows = [];
        let gridCols = [];

        let firstDate = new Date(year, month - 1, 1).getDay();
        let lastDate = new Date(year, month, 0).getDay();
        let lastOfMonth = new Date(year, month, 0).getDate();
        let monthWeeks =  Math.ceil(((firstDate + 6) % 7 + lastOfMonth) / 7);
        let usedDate = 0;
        let currentDate = 0;
        
        if (firstDate === 0) {
            firstDate = 7;
        }

        let calendarDateStatus;

        let thisMonthHolidays;
        let thisMonthName = (Object.keys(this.props.holidays).filter((propName) => propName === ("m" + month)));
        if (thisMonthName.length > 0) {
            thisMonthHolidays = this.props.holidays[thisMonthName[0]];
        }

        let thisDate = {};

        for (let j = 1; j <= monthWeeks; j++) {
            gridCols = [];

            for (let n = 1; n <= 7; n++) {
                calendarDateStatus = "cal-yh-month-date";

                if (n === 6 || n === 7) {
                    calendarDateStatus += " cal-yh-date-weekend";
                }

                if ((j === 1 && n < firstDate && firstDate !== 0) || (j === monthWeeks && n > lastDate && lastDate !== 0)) {
                    calendarDateStatus += " cal-yh-date-inactive";
                } else {
                    if (thisMonthHolidays) {
                        thisDate = (Object.keys(thisMonthHolidays).filter((propName) => propName === ("d" + (currentDate + 1))))
                        if (thisDate.length > 0) {
                            calendarDateStatus += " cal-yh-date-holiday";
                        }
                    }
                    currentDate++;
                }

                gridCols.push(
                    <td className={calendarDateStatus} key={j + "" + n}>
                        { currentDate !== usedDate ? currentDate : '' }
                    </td>
                );

                usedDate = currentDate;
            }
            
            gridRows.push(
                <tr className="cal-yh-grid-row" key={j}>
                    { gridCols }
                </tr>
            );
        }

        return (
            <div className="cal-yh-month" key={month + "." + year}>
                <div className="cal-yh-month-title">{ monthNames[month - 1] }</div>
                <div className="cal-yh-month-content">
                    <table>
                        <thead>
                            <tr>
                                <th>П</th>
                                <th>В</th>
                                <th>С</th>
                                <th>Ч</th>
                                <th>П</th>
                                <th className="cal-yh-date-weekend">С</th>
                                <th className="cal-yh-date-weekend">Н</th>
                            </tr>
                        </thead>
                        <tbody>
                            {gridRows}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    generateHolidaysText = (holidays) => {
        let holidayPars = [];

        for (let month in holidays) {
            for (let date in holidays[month]) {
                holidayPars.push(
                    <p key={date + "." + month}><b>{date.substring(1) + " " + monthNames[(month.substring(1)) - 1] + " - "}</b>{holidays[month][date]}</p>
                )
            }
        }

        return (
            <div>{ holidayPars }</div>
        )
    }

    closeHolidaysPopup = () => {
        this.props.closeYearHolidaysPopup();
    }

    render() {
        return (
            <section className={"year-" + this.props.year}>
                <div className={"popup-wrap popup-year-holidays"} onClick={this.handleClickOutside}>
                    <div className="main-box popup-content">
                        <div className="popup-title">
                            <h4>Почивни дни { this.props.year }</h4>
                        </div>
                        <div className="calendar-year-holidays">
                            { this.generateYear(this.props.year) }
                        </div>
                        <div className="calendar-year-holidays-text">
                            { this.generateHolidaysText(this.props.holidays) }
                        </div>

                        <div className="close-button">
                            <button className="close-btn" onClick={ this.closeHolidaysPopup }>
                                <div className="close-btn-arrow-left"></div>
                                <div className="close-btn-arrow-right"></div>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SectionYearHolidays;
