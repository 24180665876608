import React, {Component} from 'react';

class AdminLogin extends Component {
    render() {
        return (
            <div className="admin-login-wrap">
                <div className="admin-login-content">
                    <div className="admin-login-head">
                        <h4>Admin</h4>
                    </div>
                    <div className="admin-login-input">
                        <label for="admin-login-username">Username:</label>
                        <input type="text" name="adminUsername" className="admin-login-username main-input" />
                    </div>
                    <div className="admin-login-input">
                        <label for="admin-login-password">Password:</label>
                        <input type="text" name="adminPassowrd" className="admin-login-password main-input" />
                    </div>
                    <div className="admin-login-button">
                        <button className="main-button">
                            Вход
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminLogin;