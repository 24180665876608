import React from 'react';
import Calendar from '../MainApp/Calendar/Calendar';
import DayInfo from '../MainApp/Calendar/DayInfo';

const CalendarPage = () => (
    <div id="calendar-wrap">
        <div id="calendar-container">
            <Calendar />
        </div>
        <div id="day-info">
            <DayInfo />
        </div>
    </div>
);

export default CalendarPage;