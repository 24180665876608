import React from 'react';

import { seasonNow } from '../../utils/helpers';

const SeasonsState = () => {
    const dateSeason = seasonNow();
    const seasonsBg = {
        "spring": "Пролет",
        "summer": "Лято",
        "autumn": "Есен",
        "winter": "Зима",
    }

    return (
        <div className="astro-seasons box-astro-mini">
            <span className={"icon-season icon-" + dateSeason}></span>
            <span className="text">{seasonsBg[dateSeason]}</span>
        </div>
    )
}

export default SeasonsState;