import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { API_ROOT } from '../../utils/api-config';
import { regexMail } from '../../utils/helpers';

const ContactsPage = () => {
    const [status, setStatus] = useState("submit");

    const [validationName, setValidationName] = useState("");
    const [validationEmail, setValidationEmail] = useState("");
    const [validationMessage, setValidationMessage] = useState("");

    const handleSubmitMessage = async (e) => {
        e.preventDefault();
        setStatus("sending");

        const { name, email, message } = e.target.elements;
        
        let nameError = (name.value.length === 0 || name.value.length > 50) ? "Името трябва да е между 1 и 50 символа." : "";
        let emailError = (email.value.length > 0 && !email.value.match(regexMail)) ? "Оставете празно поле или въведете валиден имейл." : "";
        let messageError = (message.value.length < 3 || message.value.length > 500) ? "Съобщението трябва да е между 3 и 500 символа." : "";

        if (nameError.length > 0 || emailError.length > 0 || messageError > 0) {
            setValidationName(nameError);
            setValidationEmail(emailError);
            setValidationMessage(messageError);
            setStatus("submit");
            return;
        }

        let details = {
            name: name.value,
            email: email.value,
            message: message.value
        };
        
        try {
            const response = await fetch(API_ROOT + "/contact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=utf-8"
                },
                body: JSON.stringify(details)
            });
            const result = await response.json();
            if (result.sent) {
                setStatus("sent");
            } else {
                setStatus("error");
            }
        } catch(e) {
            console.log("Грешка при изпращането.");
        }
    }

    return (
        <div className="contacts-wrap">
            <div className="page-title" style={{display: "none"}}>
                <h1>Контакти</h1>
            </div>

            <div className="contacts-container">
                <div className="contacts-column contacts-column-left">
                    <div className="secondary-title">
                        <h3>Свържете се с нас</h3>
                    </div>
                    <div className="contacts-info">
                        <p>Нашият екип ще се радва да отговори на всякакви възникнали въпроси свързани с дейността ни. Попълнете формата и ние ще се свържем с вас при първа възможност.</p>
                    </div>
                    <div className="contacts-form">
                        <form onSubmit={handleSubmitMessage}>
                            <div className="contacts-form-item">
                                <label htmlFor="name">ИМЕ: </label>
                                <input type="text" id="name" className="main-input" />
                            </div>
                            {
                                validationName.length > 0 ? (
                                    <div className="contacts-error">{validationName}</div>
                                ) : (
                                    <></>
                                )
                            }
                            <div className="contacts-form-item">
                                <label htmlFor="email">МЕЙЛ: (ако искаш да ти отговорим)</label>
                                <input type="text" id="email" className="main-input" />
                            </div>
                            {
                                validationEmail.length > 0 ? (
                                    <div className="contacts-error">{validationEmail}</div>
                                ) : (
                                    <></>
                                )
                            }
                            <div className="contacts-form-item">
                                <label htmlFor="message">СЪОБЩЕНИЕ:</label>
                                <textarea id="message" className="main-input"></textarea>
                            </div>
                            {
                                validationMessage.length > 0 ? (
                                    <div className="contacts-error">{validationMessage}</div>
                                ) : (
                                    <></>
                                )
                            }
                            <div className="contacts-form-item">
                                {(status === "submit") ? (
                                    <button type="submit" className="main-button contact-form-submit">ИЗПРАТИ</button>
                                ) : (
                                    (status === "sending" || status === "sent") ? (
                                        <button type="submit" className="main-button contact-form-submit disabled-button" disabled>
                                            {status === "sending" ? "ИЗПРАЩА СЕ..." : "ИЗПРАТЕНО"}
                                        </button>
                                    ) : (
                                        <button type="submit" className="main-button contact-form-submit disabled-button" disabled>Възникна грешка, моля пишете ни на contact@chronos.bg</button>
                                    )
                                )}
                            </div>
                        </form>
                    </div>
                </div>
                <div className="contacts-column contacts-column-right main-box">
                    <div className="contacts-details">
                        <div className="secondary-title">
                            <h3>Контакти</h3>
                        </div>
                        <div className="contacts-info">
                            <p>Ако искате да ни питате нещо или да включите Вашите събития към нашата платформа, можете да се свържете с нас и чрез:</p>
                        </div>
                        <div className="contacts-details-item">
                            <p>
                                <span className="icon icon-small icon-mail"></span>
                                <span className="contacts-details-text"><a href="mailto:contact@chronos.bg">contact@chronos.bg</a></span>
                            </p>
                        </div>
                        {/* <div className="contacts-details-item">
                            <p>
                                <span className="icon icon-small icon-phone"></span>
                                <span className="contacts-details-text"><a href="tel:+359886001234">0886 001 234</a></span>
                            </p>
                        </div> */}
                        <div className="contacts-details-item">
                            <p>
                                <span className="icon icon-small icon-time"></span>
                                <span className="contacts-details-text">
                                    Работно време:<br />
                                    Понеделник - Петък<br />
                                    09:00 - 17:00
                                </span>
                            </p>
                        </div>
                        <div className="contacts-details-item contacts-details-faq">
                            <p>Може да видите най-често задаваните въпроси тук: <br /><Link to="/questions" className="contact-info-faq">Често задавани въпроси (ЧЗВ)</Link></p>
                        </div>
                        <div className="contacts-details-item contacts-details-social">
                            <h4>Последвай ни:</h4>
                            <a href="www.facebook.com" target="_blank" rel="noopener noreferrer"><span className="icon icon-main icon-social-facebook"></span></a>
                            <a href="www.linkedin.com" target="_blank" rel="noopener noreferrer"><span className="icon icon-main icon-social-linkedin"></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactsPage;