import React, {Component} from 'react';
import Iframe from 'react-iframe';
import {Redirect} from 'react-router-dom';

import {connect} from 'react-redux';
import {getEventById} from '../../actions/events.actions';

import Loader from '../MainApp/Components/Loader';
import SidebarEvents from '../../components/layout/SidebarEvents';
import { eventCategories } from '../../utils/helpers';

class EventInfoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            eventInfo: {}
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.requestEvent();
        }
    }

    requestEvent = () => {
        let { getEventById } = this.props;
        let getEvent = getEventById(this.props.match.params.id);

        getEvent.then(e => {
            /* THIS SHOULD BE REWRITTEN */
            this.setState({
                eventInfo: e.data,
                isLoading: false
            });

            if (!this.state.eventInfo) {
                return <Redirect to="/event-not-found" />
            }
        });
    }

    componentDidMount() {
        let { match, upEvents } = this.props;
        
        const eventData = (upEvents && upEvents.events) ? (
            upEvents.events.find((event) => event._id === match.props.id)
        ) : false;

        if (eventData) {
            this.setState({
                eventInfo: eventData,
                isLoading: false
            });
        } else {
            this.requestEvent();
        }
    }
    
    
    render() {
        const { eventInfo, isLoading } = this.state;

        const categoryData = eventCategories.find(item => item.name === eventInfo.category);
        const thisDay = new Date(eventInfo.fullDate);

        return (
            <div className="event-info-wrap">
                {isLoading ? (
                    <Loader />
                ) : (
                    <div className="event-page-content">
                        <div className="main-box event-data-content">
                            <div className="event-data-head">
                                <div className="event-data-media">
                                    {eventInfo.media ? (
                                        <div className="event-media" style={{ backgroundImage: "url(" + eventInfo.media + ")"}}></div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div className="event-data-param-wrap">
                                    <div className="event-data-param">
                                        <h3 className="event-param-title">Кога:</h3>
                                        <div className="event-param-text-wrap">
                                            <p className="event-param-text">
                                                {("00" + (thisDay.getDate())).slice(-2)}
                                                .
                                                {("00" + (thisDay.getMonth() + 1)).slice(-2)}
                                                .
                                                {thisDay.getFullYear()} 
                                            </p>
                                            <p className="event-param-text">{eventInfo.time}</p>
                                        </div>
                                    </div>
                                    <div className="event-data-param">
                                        <h3 className="event-param-title">Къде:</h3>
                                        <div className="event-param-text-wrap">
                                            <p className="event-param-text">{eventInfo.city}</p>
                                            <p className="event-param-text">{eventInfo.place}</p>
                                        </div>
                                    </div>
                                    <div className="event-data-param">
                                        <h3 className="event-param-title">От:</h3>
                                        <div className="event-param-text-wrap">
                                            <p className="event-param-text">{eventInfo.author}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={"event-data-category category-" + eventInfo.category}>
                                    <span className={"category-" + eventInfo.category}>{categoryData.text}</span>
                                </div>
                            </div>
                            <div className="event-data-body">
                                <h2 className="event-data-title">
                                    {eventInfo.name}
                                </h2>
                                <div className="event-data-description">
                                    <p>{eventInfo.description}</p>
                                </div>
                                <div className="event-data-source">
                                    <a href={"" + eventInfo.source} target="_blank" rel="noopener noreferrer" className="main-button">Повече</a>
                                </div>
                                <div className="event-data-map">
                                    {eventInfo.map ? (
                                        <Iframe url={eventInfo.map} className="event-map-iframe" width="100%" height="450px" />
                                    ) : ""}
                                </div>
                            </div>
                        </div>



                        
                        <div className="sidebar">
                            <div className="main-box sidebar-event-info">
                                <SidebarEvents eventInfo={this.state.eventInfo} eventType={categoryData.text} eventsCount={4} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    eventsUpcoming: state.eventsUpcoming
});

const mapDispatchToProps = {
    getEventById
};

export default connect(mapStateToProps, mapDispatchToProps)(EventInfoPage);