import React from 'react';

const ZodiacSign = (props) => {
    let { month, date } = props;
    
    const zodiacDays = [21, 20, 21, 21, 22, 22, 23, 24, 24, 24, 23, 22];
    const signs = ["aquarius", "pisces", "aries", "taurus", "gemini", "cancer", "leo", "virgo", "libra", "scorpio", "sagittarius", "capricorn"];
    const signsBg = {
        "aquarius": "Водолей",
        "pisces": "Риби",
        "aries": "Овен",
        "taurus": "Телец", 
        "gemini": "Близнак",
        "cancer": "Рак",
        "leo": "Лъв",
        "virgo": "Дева",
        "libra": "Везни",
        "scorpio": "Скорпи",
        "sagittarius": "Стрелец",
        "capricorn": "Козирог",
    }

    if (month === 0 && date <= 20) {
        month = 11;
    } else if (date < zodiacDays[month]) {
        month--;
    };
    const zodiacSign = signs[month];

    return (
        <div className="astro-zodiac box-astro-mini">
            <span className={"icon-zodiac icon-" + zodiacSign} alt={zodiacSign}></span>
            <span className="text">{signsBg[zodiacSign]}</span>
        </div>
    )
}

export default ZodiacSign;