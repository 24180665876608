import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class Admin extends Component {
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        //checkLoggedIn(this.props);
    }

    render() {
        return (
            <>
                <div className="admin-home">
                    <div className="admin-home-link">
                        <Link to="/admin-events">Events</Link>
                    </div>
                    <div className="admin-home-link">
                        <Link to="/admin-calendar">Calendar</Link>
                    </div>
                </div>
            </>
        );
    }
}
export default Admin;