import {
    LOAD_MAIN_IN_PROGRESS,
    LOAD_MAIN_FAILURE,
    LOAD_MAIN_BY_MONTH_SUCCESS
} from "../actions/dateInfo.actions";

export const mainByMonthReducer = (state = [], action) => {
    const {type, payload} = action;

    switch (type) {
        case LOAD_MAIN_BY_MONTH_SUCCESS: {
            return state.concat(payload);
        }
        case LOAD_MAIN_IN_PROGRESS:
        case LOAD_MAIN_FAILURE:
        default:
            return state;
    }
}