import HomePage from '../Pages/HomePage';
import Admin from '../User/Admin';
import AdminLogin from '../User/AdminLogin';
import AdminEvents from '../User/AdminEvents';
import AdminCalendar from '../User/AdminCalendar';
import CalendarPage from '../Pages/CalendarPage';
import EventsPage from '../Pages/EventsPage';
import EventInfoPage from '../Pages/EventInfoPage';
import FAQuestionsPage from '../Pages/FAQuestionsPage';
import addEventPage from '../Pages/AddEventPage';
import ContactsPage from '../Pages/ContactsPage';
import PageNotFound from '../Pages/PageNotFound';

const components = {
    admin: {
        url: '/admin',
        component: Admin
    },
    adminLogin: {
        url: '/admin-login',
        component: AdminLogin
    },
    adminEvents: {
        url: '/admin-events',
        component: AdminEvents
    },
    adminCalendar: {
        url: '/admin-calendar',
        component: AdminCalendar
    },
    home: {        
        url: '/home',
        component: HomePage
    },
    calendar: {
        url: '/calendar',
        component: CalendarPage
    },
    events: {
        url: '/events',
        component: EventsPage
    },
    eventPage: {
        url: '/event/:id',
        component: EventInfoPage
    },
    contacts: {
        url: '/contacts',
        component: ContactsPage
    },
    faquestions: {
        url: '/questions',
        component: FAQuestionsPage
    },
    addEvent: {
        url: '/create-event',
        component: addEventPage
    },
    notFound: {
        url: '*',
        component: PageNotFound
    },
}

export const routes = [ ...Object.values(components) ];