import {
    LOAD_EVENTS_IN_PROGRESS,
    LOAD_EVENTS_FAILURE,
    LOAD_EVENTS_UPCOMING_SUCCESS,
    LOAD_EVENTS_BY_MONTH_SUCCESS
} from "../actions/events.actions";

export const isLoading = (state = false, action) => {
    const { type } = action;

    switch (type) {
        case LOAD_EVENTS_IN_PROGRESS:
            return true;
        case LOAD_EVENTS_UPCOMING_SUCCESS:
        case LOAD_EVENTS_BY_MONTH_SUCCESS:
        case LOAD_EVENTS_FAILURE:
            return false;
        default:
            return state;
    }
}