import React from 'react';
import { Link } from 'react-router-dom';
import DateBox from '../../../components/layout/DateBox';
import { eventCategories, weekDaysShort, monthNamesShort } from '../../../utils/helpers';

const EventsItem = ({eventInfo = []}) => {
    const eventDate = new Date(eventInfo.fullDate);
    const eventCategory = eventCategories.find(item => item.name === eventInfo.category);

    return <Link to={"/event/" + eventInfo._id} className={"events-item events-item-category-" + eventCategory.name} data-author={eventInfo.author} data-city={eventInfo.city}>
        <div className="ev-img-wrap">
            {eventInfo.media && (
                <div className="event-media" style={{ backgroundImage: "url(" + eventInfo.media + ")"}}></div>
            )}
            <div className={"event-category category-" + eventCategory.name}>
                <h3 className={"category-" + eventCategory.name}>{eventCategory.text}</h3>
            </div>
        </div>
        <div className="ev-info-wrap">
            <div className="event-text">
                <h2 className="event-title">
                    {eventInfo.name}
                </h2>
            </div>
            <div className="event-where">
                <span className="event-city">
                    {eventInfo.city}
                </span>
                <span className="event-place">
                    {eventInfo.place}
                </span>
            </div>
                {/* <div className="event-time">
                    {eventInfo.time}
                </div> */}
            <DateBox weekDayText={weekDaysShort[eventDate.getDay()]} dateText={eventDate.getDate() + " " + (monthNamesShort[eventDate.getMonth()])} />
        </div>
    </Link>
}

export default EventsItem;