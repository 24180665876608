import React, {Component} from 'react';

class CalendarNavigation extends Component {
    constructor(props) {
        super(props);
        this.monthSteps = 1;
    }

    calBtnPrev = (e) => {
        if (e.currentTarget.classList.contains("cal-nav-btn-disabled")) {
            return;
        }

        this.monthSteps--;

        let calItems = document.getElementsByClassName("cal-item");

        for (let i = 0; i < calItems.length; i++) {
            if (calItems[i].classList.contains("cal-item-active")) {
                calItems[i].classList.remove("cal-item-active");
                calItems[(i-1)].classList.add("cal-item-active");
                break;
            }
        }

        if (calItems[0].classList.contains("cal-item-active")) {
            document.getElementById("cal-btn-prev").classList.add("cal-nav-btn-disabled");
        }
    }

    calBtnNext = () => {
        if (document.getElementById("cal-btn-prev").classList.contains("cal-nav-btn-disabled")) {
            document.getElementById("cal-btn-prev").classList.remove("cal-nav-btn-disabled");
        }

        if (this.monthSteps > 0) {
            const nextMonth = (this.props.time.lastMonth === 12 ? 1 : (this.props.time.lastMonth + 1));
            const nextYear = (this.props.time.lastMonth === 12 ? (this.props.time.lastYear + 1) : this.props.time.lastYear);
            
            this.props.onNewMonth(nextYear, nextMonth);
        }

        this.monthSteps = this.monthSteps > 0 ? 1 : this.monthSteps + 1;

        let calItems = document.getElementsByClassName("cal-item");
        
        for (let i = 0; i < calItems.length; i++) {
            if (calItems[i].classList.contains("cal-item-active")) {
                calItems[i].classList.remove("cal-item-active");
                calItems[(i+1)].classList.add("cal-item-active");
                break;
            }
        }
    }

    render() {
        return (
            <div className="calendar-navigation">
                <button className="cal-nav-btn cal-nav-btn-prev cal-nav-btn-disabled" id="cal-btn-prev" onClick={this.calBtnPrev}>
                    <span className="icon icon-arrow icon-arrow-prev"></span>
                </button>
                <button className="cal-nav-btn cal-nav-btn-next" id="cal-btn-next" onClick={this.calBtnNext} >
                    <span className="icon icon-arrow icon-arrow-next"></span>
                </button>
            </div>
        )
    }
}

export default CalendarNavigation;