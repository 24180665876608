import React, {Component} from 'react';
import {Route} from 'react-router-dom';

class PrivateRoute extends Component {
    render() {
        const {
            component: Component,
            layout: Layout
        } = this.props;
        const {path} = this.props;

        return (
            <Route
                path={path}
                render={(props) => (
                    <Layout path={path}>
                        <Component {...props} />
                    </Layout>
                )}
            />
        )
    }
}

export default PrivateRoute;