import React from 'react';
import { eventCategories } from '../../../../utils/helpers';

const CalendarItemEvent = (props) => {
    const categoryData = eventCategories.find(item => item.name === props.eventInfo.category);
    const categoriesStore = sessionStorage.getItem("activeCategories") || "technology health science business sport culture ecology fun";
    const isCategoryVisible = categoriesStore.includes(categoryData.name) ? true : false;

    return (
        <div className={"cal-event-item item-event-category-" + categoryData.name + " " + (!isCategoryVisible ? "hideCategory" : "")}>
            <span className={"cal-event-item-dot category-" + categoryData.name}></span>
            <h4 className="cal-event-item-title">
                { props.eventInfo.name }
            </h4>
        </div>
    );
}

export default CalendarItemEvent;


// class CalendarItemEvent extends Component {
//     constructor(props) {
//         super(props);
//         // this.state = {isActiveEventPopup: false};
//     }

//     // openEventPopup = () => {
//     //     this.setState({isActiveEventPopup: true});
//     // }

//     // closeEventPopup = () => {
//     //     this.setState({isActiveEventPopup: false});
//     // }

//     render() {
//         const categoryData = eventCategories.find(item => item.name === this.props.eventInfo.category);
//         const categoriesStore = sessionStorage.getItem("activeCategories") || "technology health science business sport culture ecology fun";
//         const isCategoryVisible = categoriesStore.includes(categoryData.name) ? true : false;

//         return <div className={"cal-event-item item-event-category-" + categoryData.name + " " + (!isCategoryVisible ? "hideCategory" : "")}>
//             <span className={"cal-event-item-dot category-" + categoryData.name}></span>
//             <h4 className="cal-event-item-title" onClick={this.openEventPopup}>
//                 { this.props.eventInfo.name }
//             </h4>
//             {/* {this.state.isActiveEventPopup && 
//                 <PopupEventItem eventInfo={this.props.eventInfo} closeEventPopup={this.closeEventPopup} />} */}
//         </div>
//     }
// }