import React, {Component} from 'react';

import SectionDonate from '../../components/sections/SectionDonate';
import SectionIntro from '../../components/sections/SectionIntro';
import SectionPartnersList from '../../components/sections/SectionPartnersList';
import SectionApplication from '../../components/sections/SectionApplication';
import SectionSummary from '../../components/sections/SectionSummary';

class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scrollElements: []
        }
    }

    componentDidMount() {
        this.setState({scrollElements: document.getElementsByClassName("appear-scroll")});

        this.state.scrollElements.forEach((el) => {
            el.style.opacity = 0
        });

        window.addEventListener('scroll', () => {
            this.handleScrollAnimation();
        });
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', () => {
            this.handleScrollAnimation();
        });
    }

    elementInView = (el, scrollOffset = 0) => {
        const elementTop = el.getBoundingClientRect().top;
       
        return (
            elementTop <= 
            ((window.innerHeight || document.documentElement.clientHeight) - scrollOffset)
        );
    };

    displayScrollElement = (element) => {
        element.classList.add("scrolled");
    };

    hideScrollElement = (element) => {
        element.classList.remove("scrolled");
    };
       
    handleScrollAnimation = () => {
        for (let el of this.state.scrollElements) {
            if (this.elementInView(el, 100)) {
                this.displayScrollElement(el);
            } else {
                this.hideScrollElement(el);
            }
        }
    }

    render() {
        return (
            <div className="home-wrap">
                <h1 style={{display: "none"}}>Chronos</h1>

                <SectionIntro />

                <SectionSummary />

                <SectionDonate />

                <SectionApplication />

                <SectionPartnersList />

            </div>
        )
    }
}

export default HomePage;