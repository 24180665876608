import React, {Component} from 'react';

class SectionPartnersList extends Component {
    render() {
        let partners = [
            {
                partnerName: "Inter Expo Center",
                partnerLink: "https://iec.bg/",
                partnerLogo: "imgs/partners/iec-logo.png"
            },
            {
                partnerName: "Dev.bg",
                partnerLink: "https://www.dev.bg/",
                partnerLogo: "imgs/partners/devbg-logo.png"
            },
            {
                partnerName: "Софийски университет",
                partnerLink: "https://www.uni-sofia.bg/",
                partnerLogo: "imgs/partners/su-logo.jpg"
            },
            {
                partnerName: "Begach Running Club",
                partnerLink: "https://www.begach.com/",
                partnerLogo: "imgs/partners/begach-logo.jpg"
            },
            {
                partnerName: "Български Планинарски Съюз",
                partnerLink: "https://bps-bg.org/kalendar/",
                partnerLogo: "imgs/partners/bps-logo.png"
            },
            {
                partnerName: "Ratio",
                partnerLink: "https://ratio.bg/",
                partnerLogo: "imgs/partners/ratio-logo.png"
            }
        ];

        return (
            <section id="section-partners-list">
                <div className="section-title appear-scroll">
                    <h2>Партньори</h2>
                </div>
                <div className="partners-list-content appear-scroll">
                    <div className="pcc-partners-list">
                        {partners.map((item, key) => (
                            <div className="pcc-partner-item main-box" key={key}>
                                <a className="pcc-partner-link" target="_blank" rel="noopener noreferrer" href={item.partnerLink} title={item.partnerName}>
                                    <span className="partner-logo-img" style={{ backgroundImage: `url("${item.partnerLogo}")`}}></span>
                                </a>
                            </div>
                        ))}
                    </div>
                    <div className="partners-list-contact">
                        <p>* Ако сте организация и желаете да публикуваме и вашите събития натиснете <a href="https://docs.google.com/forms/d/e/1FAIpQLScaLHHmQKby0TXq88x9MCmHqm7hqL5qvn6TV1VXtKe_V7iFlg/viewform" target="_blank" rel="noopener noreferrer"><span className="text-orange partners-contact-button">тук</span></a>.</p>
                    </div>
                </div>
            </section>
        );
    }
}

export default SectionPartnersList;