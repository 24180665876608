import React, {Component} from 'react';

class SectionApplication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActiveAppPopup: false
        }
    }

    openApplicationPopup = () => {
        this.setState({
            isActiveAppPopup: true
        });
    }

    closeApplicationPopup = () => {
        this.setState({
            isActiveAppPopup: false
        });
    }

    handleClickOutside = (event) => {
        if (event.target && event.target.className.includes("popup-application-info")) {
            this.closeApplicationPopup();
        }
    }

    render() {
        return (
            <section id="section-application">
                <div className="application-content">
                    <div className="app-calendar">
                        <div className="application-devices">
                            <div className="device-holder appear-scroll">
                                <div className="img-monitor icon-monitor">
                                    <div className="monitor-content">

                                    </div>
                                </div>
                                <div className="img-phone icon-mobile">
                                    <div className="phone-content">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="application-info">
                            <div className="app-info-head">
                                {/* <h3>Актуален календар</h3> */}
                                <p>
                                    Изтегли приложението за да имаш достъп до календара и събитията <span className="text-orange">навсякъде</span>.
                                </p>
                                <button className="main-button" onClick={this.openApplicationPopup}>Приложение</button>
                            </div>
                        </div>
                    </div>
                    {this.state.isActiveAppPopup ? (
                        <div className="popup-wrap popup-application-info" onClick={this.handleClickOutside}>
                            <div className="main-box popup-content">
                                <div className="icon icon-application"></div>
                                <div className="popup-title">
                                    <h4>Приложението е в процес на разработка.</h4>
                                </div>
                                <div className="close-button">
                                    <button className="close-btn" onClick={this.closeApplicationPopup}> 
                                        <div className="close-btn-arrow-left"></div>
                                        <div className="close-btn-arrow-right"></div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : ""}
                </div>
            </section>
        )
    }
}

export default SectionApplication;