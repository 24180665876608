import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import {routes} from "./routeConfig";
import PrivateRoute from './PrivateRoute';
import AdminLayout from './AdminLayout';
import UserLayout from './UserLayout';
import VisitorLayout from './VisitorLayout';
import ScrollToTop from '../../components/layout/ScrollToTop';

class MainLayout extends Component {
    render() {
        const isUserLogged = false; //to finish
        const isAdmin = false; //to finish

        return (
            <>
                <ScrollToTop />
                <Switch>
                    <Route exact path="/" render={() => <Redirect to={isAdmin ? '/admin' : '/home'} />} />
                    {routes.map((route) => (
                        <PrivateRoute
                            key={route.url}
                            path={route.url} 
                            component={route.component}
                            layout={isAdmin ? AdminLayout : isUserLogged ? UserLayout : VisitorLayout}
                            {...this.props}
                        />
                    ))}
                </Switch>
            </>
        )
    }
}

export default MainLayout;