import React, { Component } from 'react';

class SectionFAQuestions extends Component {
    changeActive = (e) => {
        if (e.currentTarget.classList.contains("question-item-head")) {
            if (!(e.currentTarget.parentNode.classList.contains("question-info-active"))) {
                e.currentTarget.parentNode.classList.add("question-info-active");
            } else if(e.currentTarget.parentNode.classList.contains("question-info-active")) {
                e.currentTarget.parentNode.classList.remove("question-info-active")
            }
        }
    }

    render() {
        const questions = [
            {
                question: "Какво представлява платформата chronos.bg?",
                answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            },
            {
                question: "Как да се свържеш с нас?",
                answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            },
            {
                question: "Как да изтеглиш нашето приложение?",
                answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            },
            {
                question: "Lorem ipsum dolor sit amet?",
                answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            },
            {
                question: "От къде идва името Chronos?",
                answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            }
        ]

        return (
            <section className="section-faquestions">
                <div className="faquestions-content">
                    <div className="faquestions-items">
                        {questions.map((item, key) => (
                            <div className="question-item" key={key}>
                                <div className="question-item-head" onClick={ this.changeActive }>
                                    <h4>{ item.question }</h4>
                                </div>
                                <div className="question-item-body">
                                    <p>{ item.answer }</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }
}

export default SectionFAQuestions;