import React, {Component} from 'react';

import SectionFAQuestions from '../../components/sections/SectionFAQuestions';

class FAQuestions extends Component {
    render() {
        return <>
            <div className="faquestions-wrap">
                <div className="page-title">
                    <h1>Често задавани въпроси</h1>
                </div>
                
                <SectionFAQuestions />
            </div>
        </>
    }
}

export default FAQuestions;