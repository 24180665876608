import React from 'react';
import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Footer = (props) => (
    <footer>
        <div className="footer-nav">
            <div className="footer-nav-inner">
                <div className="footer-column">
                    <div className="footer-column-title">
                        Страници
                    </div>
                    <div className="footer-column-content">
                        <ul>
                            <li><Link to="/">Начало</Link></li>
                            <li><Link to="/calendar">Календар</Link></li>
                            <li><Link to="/events">Събития</Link></li>
                            <li><Link to="/contacts">Контакти</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-column">
                    <div className="footer-column-title">
                        Полезно
                    </div>
                    <div className="footer-column-content">
                        <ul>
                            <li><HashLink to="/home#section-intro">Днес</HashLink></li>
                            <li><HashLink to="/home#section-summary">Какво е Chronos</HashLink></li>
                            <li><HashLink to="/home#section-donate">Подкрепи проекта</HashLink></li>
                            <li><HashLink to="/home#section-application">Свали приложение</HashLink></li> {/*to finish */}
                            <li><HashLink to="/home#section-partners-list">Партньори</HashLink></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-column">
                    <div className="footer-column-title">
                        Помощ
                    </div>
                    <div className="footer-column-content">
                        <ul>
                            <li><Link to="/contacts">Контакти</Link></li>
                            <li><Link to="/questions">ЧЗВ</Link></li>
                            <li><Link to="/">Правила и условия</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-column">
                    <div className="footer-column-title">
                        Контакти
                    </div>
                    <div className="footer-column-content">
                        <div className="footer-contacts">
                            <div className="footer-contact-item">
                                <p>
                                    <span className="icon icon-small icon-mail"></span>
                                    <span className="footer-contact-text"><a href="mailto:contact@chronos.bg">contact@chronos.bg</a></span>
                                </p>
                            </div>
                            {/* <div className="footer-contact-item">
                                <p>
                                    <span className="icon icon-small icon-phone"></span>
                                    <span className="footer-contact-text"><a href="tel:+359886001234">0886 001 234</a></span>
                                </p>
                            </div> */}
                            <div className="footer-contact-item">
                                <p>
                                    <span className="icon icon-small icon-time"></span>
                                    <span className="footer-contact-text">Работно време: <br />Понеделник - Петък <br />09:00 - 17:00</span>
                                </p>
                            </div>
                        </div>
                        <div className="footer-contact-social">
                            <a className="footer-button-social" href="/">
                                <span className="icon icon-main icon-social-facebook"></span>
                            </a>
                            <a className="footer-button-social" href="/">
                                <span className="icon icon-main icon-social-linkedin"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="footer-content">
            <div className="footer-content-inner">
                <div className="fc-copyright">
                    <p className="fc-copyright-text">&#169; Chronos 2024</p>
                </div>
                <div className="fc-source">
                    <p className="fc-source-text">Developed by: <a href="https://tsaddy.com/" target="_blank" rel="noopener noreferrer">Tsaddy</a></p>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;