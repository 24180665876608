import React, {Component} from 'react';
import {connect} from 'react-redux';
import { eventCategories } from '../../../utils/helpers';

class EventsFilter extends Component {
    constructor(props) {
        super(props)
        this.setSessionStorageDropdownAll("eventsDropdownCategory")
        this.setSessionStorageDropdownAll("eventsDropdownOrganization")
        this.setSessionStorageDropdownAll("eventsDropdownCity")
    }

    componentDidMount() {
        document.addEventListener("click", this.eventsFilterDropdownClose)

        // document.getElementById("dropdown-organization-search").addEventListener("keyup", this.dropdownSearchFilter)
        // document.getElementById("dropdown-city-search").addEventListener("keyup", this.dropdownSearchFilter)

        if (sessionStorage.getItem("eventsDropdownCategory") === "all" || sessionStorage.getItem("eventsDropdownCategory") === null) {
            document.getElementById("dropdown-category-all").checked = true
        }
        if (sessionStorage.getItem("eventsDropdownOrganization") === "all" || sessionStorage.getItem("eventsDropdownOrganization") === null) {
            document.getElementById("dropdown-organization-all").checked = true
        }
        if (sessionStorage.getItem("eventsDropdownCity") === "all" || sessionStorage.getItem("eventsDropdownCity") === null) {
            document.getElementById("dropdown-city-all").checked = true
        }

        // shown items by filter:
        // ...
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.eventsFilterDropdownClose);
    }

    setSessionStorageDropdownAll = (element) => {
        if (sessionStorage.getItem(element) === null) {
            sessionStorage.setItem(element, "all")
        }
    }

    eventsFilterDropdownOpen = (selectedElem) => {
        let targetElem = selectedElem.target;
        let parentClass = targetElem.parentNode.className;
        let dropdownClass = "events-filter-dropdown-";
        let dropdownClassActive = "events-filter-dropdown-el-active";
        let arrowClassActive = "events-dropdown-arrow-active";

        if (parentClass.includes("category")) {
            dropdownClass += "category";
        } else if (parentClass.includes("organization")) {
            dropdownClass += "organization";
        } else if (parentClass.includes("city")) {
            dropdownClass += "city";
        } else if (parentClass.includes("date")) {
            dropdownClass += "date";
        }

        if (!document.getElementsByClassName(dropdownClass)[0].classList.contains(dropdownClassActive)) {
            let activeDrops = document.getElementsByClassName(dropdownClassActive);
            [].forEach.call(activeDrops, function(el) {
                el.classList.remove(dropdownClassActive);
            });
            document.getElementsByClassName(dropdownClass)[0].classList.add(dropdownClassActive);
        }
        if (!targetElem.classList.contains(arrowClassActive)) {
            let activeArrows = document.getElementsByClassName(arrowClassActive);
            [].forEach.call(activeArrows, function(el) {
                el.classList.remove(arrowClassActive);
            });
            targetElem.classList.add(arrowClassActive);
        }
    }

    eventsFilterDropdownClose = (selectedElem) => {
        let isDropdownActive = this.hasSomeParentClass(selectedElem.target, "events-filter-dropdown-el-active")

        if (document.getElementsByClassName("events-filter-dropdown-el-active").length > 0) {
        
            if (!(selectedElem.target.classList.contains("events-dropdown-arrow-active")) && !isDropdownActive) {
                document.getElementsByClassName("events-filter-dropdown-el-active")[0].classList.remove("events-filter-dropdown-el-active");
                document.getElementsByClassName("events-dropdown-arrow-active")[0].classList.remove("events-dropdown-arrow-active");
            }
        }
    }

    hasSomeParentClass = (element, classname) => {
        if (element.className && element.className.split(' ').indexOf(classname)>=0) return true;
        return element.parentNode && this.hasSomeParentClass(element.parentNode, classname);
    }

    dropdownSearchFilter = (selectedElem) => {
        let searchedPhrase = selectedElem.target.value.toLowerCase()

        // show only options that include the searchedPhrase
        let parentElem = selectedElem.target.parentElement.parentElement.parentElement
        let optionElems = parentElem.getElementsByClassName("dropdown-option-item")

        for (let i = 0; i < optionElems.length; i++) {
            if (!optionElems[i].getElementsByTagName("span")[0].innerText.toLowerCase().includes(searchedPhrase)) {
                optionElems[i].classList.add("dropdown-option-item-hidden")
            } else {
                optionElems[i].classList.remove("dropdown-option-item-hidden")
            }
        }
    }

    getFilterItems = (type) => {
        const items = [];

        // rework to be without if but with ternary directly after const items = ... ? ev.category[type] : []
        if (this.props?.eventsUpcoming?.events?.length > 0) {
            this.props.eventsUpcoming.events.map((ev) => {
                if (!items.includes(ev[type])) {
                    items.push(ev[type]);
                }
            })
        }

        return items;
    }

    createFilterDropdownItems = (type) => {
        const filterItems = this.getFilterItems(type);

        const itemOptions = filterItems.map((item, key) => (
            <div className="events-filter-dropdown-option dropdown-option-item" key={key}>
                <label>
                    <input className={`dropdown-input-${type}`} type="checkbox" value={item} onClick={
                        //fix this and the functions that this is calling to be one general function
                        type === "category" ? this.dropdownSelectOptionCategory
                            : type === "author" ? this.dropdownSelectOptionOrganization
                            : type === "city" ? this.dropdownSelectOptionCity
                            : null
                    } />
                    <span>{type === "category" ? eventCategories.find(cats => cats.name === item).text : item}</span>
                </label>
            </div>
        ));

        return itemOptions;
    }

    dropdownSelectOptionCategory = (el) => {
        document.getElementById("dropdown-organization-all").checked = true;
        document.getElementById("dropdown-city-all").checked = true;
        for (let i = 0; i < document.getElementsByClassName("dropdown-input-author").length; i++) {
            document.getElementsByClassName("dropdown-input-author")[i].checked = false;
        }
        for (let i = 0; i < document.getElementsByClassName("dropdown-input-city").length; i++) {
            document.getElementsByClassName("dropdown-input-city")[i].checked = false;
        }

        if (el.target.classList.contains("dropdown-input-category")) {
            if (document.getElementById("dropdown-category-all").checked) {
                let allEvents = document.getElementsByClassName("events-item")
                for (let i = 0; i < allEvents.length; i++) {
                    allEvents[i].style.opacity = "0";
                    allEvents[i].style.display = "none";
                }
                document.getElementById("dropdown-category-all").checked = false;
            }

            let categoryEvents = document.getElementsByClassName("events-item-category-" + el.target.value) 
            if (el.target.checked) {
                for (let j = 0; j < categoryEvents.length; j++) {
                    categoryEvents[j].style.display = "block";
                    setTimeout(() => {
                        categoryEvents[j].style.opacity = "1";
                    }, 300)
                }
            } else {
                for (let j = 0; j < categoryEvents.length; j++) {
                    categoryEvents[j].style.opacity = "0";
                    setTimeout(() => {
                        categoryEvents[j].style.display = "none";
                    }, 300)
                }
            }
        } else if (el.target.id.includes("dropdown-category-all")) {
            if (el.target.checked) {
                for (let i = 0; i < document.getElementsByClassName("dropdown-input-category").length; i++) {
                    document.getElementsByClassName("dropdown-input-category")[i].checked = false;
                }
            } else {
                el.target.checked = true;
            }

            let allEvents = document.getElementsByClassName("events-item")
            for (let i = 0; i < allEvents.length; i++) {
                allEvents[i].style.display = "block";
                setTimeout(() => {
                    allEvents[i].style.opacity = "1";
                }, 300);
            }
        }
    }

    dropdownSelectOptionOrganization = (el) => {
        document.getElementById("dropdown-category-all").checked = true;
        document.getElementById("dropdown-city-all").checked = true;
        for (let i = 0; i < document.getElementsByClassName("dropdown-input-category").length; i++) {
            document.getElementsByClassName("dropdown-input-category")[i].checked = false;
        }
        for (let i = 0; i < document.getElementsByClassName("dropdown-input-city").length; i++) {
            document.getElementsByClassName("dropdown-input-city")[i].checked = false;
        }

        if (el.target.classList.contains("dropdown-input-author")) {
            if (document.getElementById("dropdown-organization-all").checked) {
                let allEvents = document.getElementsByClassName("events-item");
                for (let i = 0; i < allEvents.length; i++) {
                    allEvents[i].style.opacity = "0";
                    allEvents[i].style.display = "none";
                }
                document.getElementById("dropdown-organization-all").checked = false;
            }

            let organizationEvents = document.querySelectorAll('[data-author="' + el.target.value + '"]');
            if (el.target.checked) {
                for (let j = 0; j < organizationEvents.length; j++) {
                    organizationEvents[j].style.display = "block";
                    setTimeout(() => {
                        organizationEvents[j].style.opacity = "1";
                    }, 300)
                }
            } else {
                for (let j = 0; j < organizationEvents.length; j++) {
                    organizationEvents[j].style.opacity = "0";
                    setTimeout(() => {
                        organizationEvents[j].style.display = "none";
                    }, 300)
                }
            }
        } else if (el.target.id.includes("dropdown-organization-all")) {
            if (el.target.checked) {
                for (let i = 0; i < document.getElementsByClassName("dropdown-input-author").length; i++) {
                    document.getElementsByClassName("dropdown-input-author")[i].checked = false;
                }
            } else {
                el.target.checked = true;
            }

            let allEvents = document.getElementsByClassName("events-item");
            for (let i = 0; i < allEvents.length; i++) {
                allEvents[i].style.display = "block";
                setTimeout(() => {
                    allEvents[i].style.opacity = "1";
                }, 300);
            }
        }
    }

    dropdownSelectOptionCity = (el) => {
        document.getElementById("dropdown-category-all").checked = true;
        document.getElementById("dropdown-organization-all").checked = true;
        for (let i = 0; i < document.getElementsByClassName("dropdown-input-category").length; i++) {
            document.getElementsByClassName("dropdown-input-category")[i].checked = false;
        }
        for (let i = 0; i < document.getElementsByClassName("dropdown-input-author").length; i++) {
            document.getElementsByClassName("dropdown-input-author")[i].checked = false;
        }

        if (el.target.classList.contains("dropdown-input-city")) {
            if (document.getElementById("dropdown-city-all").checked) {
                let allEvents = document.getElementsByClassName("events-item");
                for (let i = 0; i < allEvents.length; i++) {
                    allEvents[i].style.opacity = "0";
                    allEvents[i].style.display = "none";
                }
                document.getElementById("dropdown-city-all").checked = false
            }

            let cityEvents = document.querySelectorAll('[data-city="' + el.target.value + '"], [data-city=""]')
            if (el.target.checked) {
                for (let j = 0; j < cityEvents.length; j++) {
                    cityEvents[j].style.display = "block";
                    setTimeout(() => {
                        cityEvents[j].style.opacity = "1";
                    }, 300)
                }
            } else {
                for (let j = 0; j < cityEvents.length; j++) {
                    cityEvents[j].style.opacity = "0";
                    setTimeout(() => {
                        cityEvents[j].style.display = "none";
                    }, 300)
                }
            }
        } else if (el.target.id.includes("dropdown-city-all")) {
            if (el.target.checked) {
                for (let i = 0; i < document.getElementsByClassName("dropdown-input-city").length; i++) {
                    document.getElementsByClassName("dropdown-input-city")[i].checked = false;
                }
            } else {
                el.target.checked = true
            }

            let allEvents = document.getElementsByClassName("events-item");
            for (let i = 0; i < allEvents.length; i++) {
                allEvents[i].style.display = "block"
                setTimeout(() => {
                    allEvents[i].style.opacity = "1"
                }, 300);
            }
        }
    }

    render() {
        return <div className="events-filter-wrap">
            <form className="events-filter-form">
                <div className="events-filter">
                    <div className="events-filter-list">
                        <div className="events-filter-options-input">
                            <div className="events-filter-option events-filter-option-category">
                                <button type="button" className="events-filter-option-selector events-dropdown-arrow" onClick={this.eventsFilterDropdownOpen}>
                                    <span className="events-filter-option-label">Категории: </span>
                                </button>
                            
                                <div className="events-filter-dropdown-el events-filter-dropdown-category">
                                    <div className="events-filter-dropdown-el-content">
                                        <div className="events-filter-dropdown-options-wrap">
                                            <div className="events-filter-dropdown-option events-filter-dropdown-option-all">
                                                <label>
                                                    <input type="checkbox" id="dropdown-category-all" onClick={this.dropdownSelectOptionCategory} />
                                                    <span>Всички</span>
                                                </label>
                                            </div>
                                            {this.createFilterDropdownItems("category")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="events-filter-option events-filter-option-organization">
                                <button type="button" className="events-filter-option-selector events-dropdown-arrow" onClick={this.eventsFilterDropdownOpen}>
                                    <span className="events-filter-option-label">Организации: </span>
                                </button>

                                <div className="events-filter-dropdown-el events-filter-dropdown-organization">
                                    <div className="events-filter-dropdown-el-content">
                                        {/* <div className="events-filter-dropdown-search-wrap">
                                            <input type="text" id="dropdown-organization-search" autoComplete="off" />
                                            <span className="dropdown-search-icon"></span>
                                        </div> */}
                                        <div className="events-filter-dropdown-options-wrap">
                                            <div className="events-filter-dropdown-option events-filter-dropdown-option-all">
                                                <label>
                                                    <input type="checkbox" id="dropdown-organization-all" onClick={this.dropdownSelectOptionOrganization} />
                                                    <span>Всички</span>
                                                </label>
                                            </div>
                                            {this.createFilterDropdownItems("author")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="events-filter-option events-filter-option-city">
                                <button type="button" className="events-filter-option-selector events-dropdown-arrow" onClick={this.eventsFilterDropdownOpen}>
                                    <span className="events-filter-option-label">Местоположение: </span>
                                </button>

                                <div className="events-filter-dropdown-el events-filter-dropdown-city">
                                    <div className="events-filter-dropdown-el-content">
                                        {/* <div className="events-filter-dropdown-search-wrap">
                                            <input type="text" id="dropdown-city-search" autoComplete="off" />
                                            <span className="dropdown-search-icon"></span>
                                        </div> */}
                                        <div className="events-filter-dropdown-options-wrap">
                                            <div className="events-filter-dropdown-option events-filter-dropdown-option-all">
                                                <label>
                                                    <input type="checkbox" id="dropdown-city-all" onClick={this.dropdownSelectOptionCity} />
                                                    <span>Всички</span>
                                                </label>
                                            </div>
                                            {this.createFilterDropdownItems("city")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="events-filter-option events-filter-option-date">
                                <button type="button" className="events-filter-option-selector events-dropdown-arrow" onClick={this.eventsFilterDropdownOpen}>
                                    <span className="events-filter-option-label">Дата: </span>
                                </button>

                                <div className="events-filter-dropdown-el events-filter-dropdown-date">
                                    <div className="events-filter-dropdown-date-wrap">
                                        { https://vaadin.com/components/vaadin-date-picker/html-install }
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        {/* <div className="events-filter-search">
                            <input type="text" className="events-filter-search-input" />
                            <span className="filter-search-icon"></span>
                        </div> */}
                    </div>
                </div>
            </form>
        </div>
    }
}

const mapStateToProps = state => ({
    eventsUpcoming: state.eventsUpcoming
});

export default connect(mapStateToProps)(EventsFilter);