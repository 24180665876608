import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import {loadEventsUpcoming} from '../../thunks.js';

import Loader from '../MainApp/Components/Loader';

class AdminEvents extends Component {
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        //checkLoggedIn(this.props);

        this.props.startLoadingEventsUpcoming();
    }

    render() {
        const { isLoading, events } = this.props;
        
        const eventItems = events.map((event, key) => (
            <div className="admin-events-item" key={key}>
                <div className="admin-events-item-info">
                    {event.name}
                </div>
                <div className="admin-events-item-options">
                    <button className="main-button admin-event-option admin-event-option-edit">
                        <span>Edit</span>
                    </button>
                    <button className="main-button admin-event-option admin-event-option-delete">
                        <span>Delete</span>
                    </button>
                </div>
            </div>
        ));

        if (eventItems.length === 0) {
            eventItems.push(<div className="events-no-item" key="no-events">Няма налични събития в календара.</div>);
        }

        return (
            <div className="admin-wrap">
                <div className="admin-content">
                    <div className="admin-events-list">
                        {isLoading ? (
                            <Loader />
                        ) : (
                            eventItems
                        )}
                    </div>
                    <div className="admin-events-options">
                        <Link to="/create-event" className="main-button">Add event</Link>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    events: state.events,
    isLoading: state.isLoading
});

const mapDispatchToProps = dispatch => ({
    startLoadingEventsUpcoming: () => dispatch(loadEventsUpcoming())
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminEvents);