import React from 'react';

const DayInfo = () => {
    
    return (
        <div className="day-info-wrap">
            <div className="day-title">
                <h3>1 януари</h3>
            </div>
            {/* <div className="popup-title">
                <h4>{ ("0" + this.state.activeDate).slice(-2) + "." + ("0" + this.props.time.month).slice(-2) + "." + this.props.time.year }</h4>
            </div> */}

            <div className="day-info">
                <div className="day-holiday">
                    Официален почивен ден!
                </div>

                <div className="day-celebration">
                    Ивановден
                </div>

                <div className="day-name">
                    Имен ден празнуват: Пешо, Гошо и Киро.
                </div>

                <div className="day-moon">
                    Фаза на луната: пълнолуние 02:35:26
                </div>
            </div>

            <div className="day-events-wrap">
                <h4>Събития:</h4>
                <div className="day-events">
                    <p>Събитие 1</p>
                    <p>Събитие 2</p>
                    <p>Събитие 3</p>
                    <p>Събитие 4</p>
                    <p>Събитие 5</p>
                </div>
            </div>
        </div>
    )
};

export default DayInfo;