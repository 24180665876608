import React, {useEffect} from 'react';
import EventsContent from './EventsContent';
import EventsFilter from './EventsFilter';
import {connect} from 'react-redux';

import Loader from '../Components/Loader';
import {loadEventsUpcoming} from '../../../thunks';

const Events = ({ eventsUpcoming = [], isLoading, startLoadingEventsUpcoming}) => {
    useEffect(() => {
        startLoadingEventsUpcoming();
    }, []);

    const content = (eventsUpcoming.events && eventsUpcoming.events.length > 0) ? (
        <div className="events-react">
            <EventsFilter />
            <EventsContent />
        </div>
    ) : (
        <div className="events-no-item">
            <span className="icon icon-error"></span>
            <p>Няма налични събития в календара или има проблем с връзката с базата.</p>
        </div>
    );
    
    return isLoading ? <Loader /> : content;
};

const mapStateToProps = state => ({
    eventsUpcoming: state.eventsUpcoming,
    isLoading: state.isLoading
});

const mapDispatchToProps = dispatch => ({
    startLoadingEventsUpcoming: () => dispatch(loadEventsUpcoming())
});

export default connect(mapStateToProps, mapDispatchToProps)(Events);