import React from 'react';
import CalendarItemMonth from './CalendarItemMonth';
import { monthNames } from '../../../../utils/helpers';

const CalendarItem = (props) => (
    <div className={ props.isFirst === true ? "cal-item cal-item-active" : "cal-item" }>
        <div className="cal-item-head">
            <div className="cal-nav-title">
                <h2>
                    <span>{ monthNames[props.time.month - 1] } </span>
                    <span>{ props.time.year }</span>
                </h2>
            </div>
        </div>
        <div className="cal-item-body">
            <CalendarItemMonth time={props.time} />
        </div>
    </div>
);

export default CalendarItem;