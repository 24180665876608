import React, {Component} from 'react';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import BackToTopButton from '../../components/layout/BackToTopButton';
import {changeChronosTheme} from '../../utils/helpers';

class UserLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTheme: (localStorage.getItem("chronosTheme") ? localStorage.getItem("chronosTheme") : "light")
        }
    }

    changeTheme = () => {
        const newTheme = changeChronosTheme();
        this.setState({
            currentTheme: newTheme
        });
    }

    render() {
        const isHome = this.props.path.includes('home'); //to rewrite
        const isCalendar = this.props.path.includes('calendar');
        let mainClass = (isHome ? "home-page " : (isCalendar ? "calendar-page " : ""));
        mainClass += ("theme-" + this.state.currentTheme);

        return (
            <div className={mainClass}>
                <Header path={this.props.path} changeTheme={this.changeTheme} theme={this.state.currentTheme} />
    
                <div className={"main-content"}>
                    <div>Logged User layout</div>
    
                    {this.props.children}
                </div>
    
                <BackToTopButton />
    
                <Footer />
            </div>
        )
    }

}

export default UserLayout;