import React from 'react';

const SectionDonate = () => (
    <section id="section-donate">
        <div className="donate-content appear-scroll">
            <div className="main-box dc-payment">
                <div className="dc-payment-title">
                    <h2>Направи дарение</h2>
                </div>
                <div className="dc-donation-info-content">
                    <div className="dc-info-text">
                        <p>Ще използваме твоята помощ за:</p>
                        <ul>
                            <li>
                                <span className="icon icon-main icon-support"></span>
                                <span className="dc-info-text-item">Редовна актуализация на съдържанието</span>
                            </li>
                            <li>
                                <span className="icon icon-main icon-development"></span>
                                <span className="dc-info-text-item">Разработване на нови функционалности</span>
                            </li>
                            <li>
                                <span className="icon icon-main icon-application"></span>
                                <span className="dc-info-text-item">Мобилно приложение</span>
                            </li>
                            <li>
                                <span className="icon icon-main icon-advertise"></span>
                                <span className="dc-info-text-item">Разпространение на платформата</span>
                            </li>
                        </ul>
                    </div>
                    <div className="dc-payment-methods">
                        <p>Как да ни подкрепиш:</p>
                        <div className="payment-methods-list">
                            
                            <a href="#" className="main-button method method-card">
                                <span className="text">Карта</span>
                            </a>
                            <a href="#" className="main-button method method-revolut">
                            </a>
                            <a href="#" className="main-button method method-paypal">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        
            <div className="dc-bank-number">
                <div className="row-bank-num iban-num">
                    <span className="bank-num-title">IBAN:</span>
                    <span>AAAA 11111 22222222222</span>
                </div>
                <div className="row-bank-num bic-num">
                    <span className="bank-num-title">BIC:</span>
                    <span>XXXXXXXXXXX</span>
                </div>
            </div>
        </div>
    </section>
);

export default SectionDonate;