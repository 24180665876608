import React from 'react';

const TodayInfo = (props) => (
    <div className="today-info">
        {(props.dayInfo.hasOwnProperty("official_holiday") && props.dayInfo.official_holiday.length > 0) ? (
            <h4 className="intro-today-holiday">
                <span>Официален почивен ден</span>
                <span>{props.dayInfo.official_holiday}</span>
            </h4>
        ) : ""}
        {(props.dayInfo.hasOwnProperty("celeb_day") && props.dayInfo.celeb_day.length > 0) ? (
            <h4 className="intro-today-celebration">{props.dayInfo.celeb_day}</h4>
        ) : ""}
        {(props.dayInfo.hasOwnProperty("name_day") && props.dayInfo.name_day.length > 0) ? (
            <h4 className="intro-today-nameday">
                <span className="nameday-text">
                    Имен ден:
                </span>
                <span>
                    {props.dayInfo.name_day}
                </span>
            </h4>
        ) : ""}
    </div>
)

export default TodayInfo;