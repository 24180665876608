import React, {Component} from 'react';
import Events from '../MainApp/Events/Events';

class EventsPage extends Component {
    render() {
        return (
            <div className="events-wrap">
                <div className="page-title">
                    <h1>ПРЕДСТОЯЩИ СЪБИТИЯ</h1>
                </div>

                <div id="events-container">
                    <Events />
                </div>
            </div>
        )
    }
}

export default EventsPage;