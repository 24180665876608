import React, {Component} from 'react';
import { eventCategories } from '../../../utils/helpers';

const calendarInstruments = [
    // { name: "Holiday", text: "Официални празници/почивни дни" },
    { name: "Celebration", text: "Неофициални и църковни празници" },
    { name: "Moon", text: "Фази на луната" }
];

class CalendarSettingsPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // activeHoliday: sessionStorage.getItem("isActiveHolidayState") || "1",
            activeCelebration: sessionStorage.getItem("isActiveCelebrationState") || "1",
            activeMoon: sessionStorage.getItem("isActiveMoonState") || "1",
            activeCategories: sessionStorage.getItem("activeCategories") || "technology health science business sport culture ecology fun"
        }
    }

    handleClickOutside = (event) => {
        if (event.target && event.target.className.includes("popup-calendar-settings")) {
            this.props.closeSettingsPopup();
        }
    }

    createInstruments = () => {
        return calendarInstruments.map((item, key) => (
            <div className="cal-instrument-option" key={key}>
                <label className="cal-opt-switch">
                    <input type="checkbox" id={item.name} onChange={this.instrumentVisibility} checked={this.state["active" + item.name] === "1" ? true : false} />
                    <span className="cal-opt-slider"></span>
                </label>
                <span className="cal-opt-text">{item.text}</span>
            </div>
        ));
    }

    instrumentVisibility = (element) => {
        let elem = element.target;

        switch(elem.id) {
            case "Moon":
                this.setState({
                    activeMoon: (elem.checked ? "1" : "0")
                });
                sessionStorage.setItem("isActiveMoonState", elem.checked ? "1" : "0");

                let moonElems = document.getElementsByClassName("cal-date-moon-phase");
                for (let i = 0; i < moonElems.length; i++) {
                    moonElems[i].classList.toggle("hideInstrument");
                }
                break;
            // case "Holiday":
            //     this.setState({
            //         activeHoliday: (elem.checked ? "1" : "0")
            //     });
            //     sessionStorage.setItem("isActiveHolidayState", elem.checked ? "1" : "0");

            //     let holidayElems = document.getElementsByClassName("cal-date-off-holiday");
            //     for (let i = 0; i < holidayElems.length; i++) {
            //         holidayElems[i].classList.toggle("hideInstrument");
            //     }
            //     break;
            case "Celebration":
                this.setState({
                    activeCelebration: (elem.checked ? "1" : "0")
                });
                sessionStorage.setItem("isActiveCelebrationState", elem.checked ? "1" : "0");

                let celebrationElems = document.getElementsByClassName("cal-date-celeb-day");
                for (let i = 0; i < celebrationElems.length; i++) {
                    celebrationElems[i].classList.toggle("hideInstrument");
                }
                break;
            default:
                break;
        }
    }

    createCategories = () => {
        return eventCategories.map((item, key) => (
            <div className="cal-category-option" key={key}>
                <input type="checkbox" id={item.name} onChange={this.categoryVisibility} checked={this.state.activeCategories.includes(item.name)} />   
                <label htmlFor={item.name}>{item.text}</label>
            </div>
        ));
    }

    categoryVisibility = (element) => {
        const targetCategory = element.target.id;

        let newCategories = "";
        const araCategories = this.state.activeCategories.split(" ");
        if (araCategories.includes(targetCategory)) {
            newCategories = araCategories.filter(item => item !== targetCategory).join(" ");
        } else {
            araCategories.push(targetCategory);
            newCategories = araCategories.join(" ");
        }
        this.setState({
            activeCategories: newCategories
        });
        sessionStorage.setItem("activeCategories", newCategories);

        let categoryElems = document.getElementsByClassName("item-event-category-" + targetCategory);
        for (let i = 0; i < categoryElems.length; i++) {
            categoryElems[i].classList.toggle("hideCategory");
        }
    }

    // createCities = () => {
    //     let cityElements = [];

    //     let cities = ["София","Пловдив", "Бургас"]; //add real from database

    //     for (let i = 0; i < cities.length; i++) {
    //         cityElements.push(
    //             <div className="cal-city-option">
    //                 <input type="checkbox" id={"city-" + i} defaultChecked />   
    //                 <label htmlFor={"city-" + i}>{cities[i]}</label>
    //             </div>
    //         );
    //     }
    //     return cityElements;
    // }

    // createOrganizations = () => {
    //     let organizationElements = [];

    //     let organizations = ["Ratio", "Inter Expo Center", "СУ - Катедра Астрономия"]; //add real from database

    //     for (let i = 0; i < organizations.length; i++) {
    //         organizationElements.push(
    //             <div className="cal-organization-option">
    //                 <input type="checkbox" id={"organization-" + i} defaultChecked />   
    //                 <label htmlFor={"organization-" + i}>{organizations[i]}</label>
    //             </div>
    //         )
    //     }
    //     return organizationElements;
    // }

    closeCalendarSettingsPopup = () => {
        this.props.closeSettingsPopup();
    }

    render() {
        return (
            <div className="popup-wrap popup-calendar-settings" onClick={this.handleClickOutside}>
                <div className="main-box popup-content">
                    <div className="popup-title">
                        <h4>НАСТРОЙКИ</h4>
                    </div>
                    <hr />
                    <div className="cal-settings-options">
                        <div className="cal-settings-instruments">
                            <h3>Инструменти:</h3>
                            <div className="cal-instrument-picker">
                                {this.createInstruments()}
                            </div>
                        </div>
                        <hr />
                        <div className="cal-settings-category">
                            <h3>Категории събития:</h3>
                            <div className="cal-category-picker">
                                {this.createCategories()}
                            </div>
                        </div>
                        {/* <hr />
                        <div className="cal-settings-cities">
                            <h3>Местоположение:</h3>
                            <div className="cal-city-picker">
                                {this.createCities()}
                            </div>
                        </div>
                        <hr />
                        <div className="cal-settings-organizations">
                            <h3>Организатори:</h3>
                            <div className="cal-organization-picker">
                                {this.createOrganizations()}
                            </div>
                        </div> */}
                    </div>

                    <div className="close-button">
                        <button className="close-btn" onClick={ this.closeCalendarSettingsPopup }>
                            <div className="close-btn-arrow-left"></div>
                            <div className="close-btn-arrow-right"></div>
                        </button>
                    </div>
                </div>
            </div>
        )
    } 
}

export default CalendarSettingsPopup;