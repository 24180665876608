import React, {Component} from 'react';
import {connect} from 'react-redux';

import CalendarNavigation from './CalendarNavigation';
import CalendarSettings from './CalendarSettings';
import CalendarFullScreen from './CalendarFullScreen';
import CalendarItem from './CalendarItem/CalendarItem';
import SectionYearHolidays from '../../../components/sections/SectionYearHolidays';

import { yearHolidays } from '../../../utils/helpers';

import {loadEventsByMonth, loadMainByMonth} from '../../../thunks';
import Loader from '../Components/Loader';

class Calendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            monthsContent: [],
            isActiveHolidaysPopup: false,
            holidaysPopupActiveYear: 2024,
            //isActiveLegendPopup: false //TOCHECK - add easy tooltip system
        }
    }

    componentDidMount() {
        const thisMonth = new Date().getMonth() + 1;
        const thisYear = new Date().getFullYear();
        const nextMonth = (thisMonth === 12 ? 1 : (thisMonth + 1));
        const nextYear = (nextMonth === 1 ? (thisYear + 1) : thisYear);

        this.addMonth(thisYear, thisMonth, true);
        this.addMonth(nextYear, nextMonth);
    }

    addMonth = (year, month) => {
        const newMonth = {
            year: year,
            month: month
        }

        this.setState(prevState => ({
            monthsContent: [...prevState.monthsContent, newMonth]
        }));

        if (this.props.eventsByMonth.length === 0 || !this.props.eventsByMonth.some((monthItem) => (monthItem.month === newMonth.month && monthItem.year === newMonth.year))) {
            this.props.startLoadingEventsByMonth(newMonth);
        }

        if (!this.props.mainByMonth.some((monthItem) => (monthItem.month === newMonth.month && monthItem.year === newMonth.year))) {
            this.props.startLoadingMainByMonth(newMonth);
        }
    }

    openYearHolidaysPopup = () => {
        this.setState({
            isActiveHolidaysPopup: true
        });
    }

    closeYearHolidaysPopup = () => {
        this.setState({
            isActiveHolidaysPopup: false
        });
    }

    // handleClickOutside = (event) => {
    //     if (event.target && event.target.className.includes("popup-calendar-legend")) {
    //         this.closeCalendarLegend();
    //     }
    // }

    // openCalendarLegend = () => {
    //     this.setState({
    //         isActiveLegendPopup: true
    //     });
    // }

    // closeCalendarLegend = () => {
    //     this.setState({
    //         isActiveLegendPopup: false
    //     });
    // }

    render() {
        const { monthsContent } = this.state;

        return (
            monthsContent.length === 0 ? (
                <Loader /> /*TOCHECK - REWORK NOT HERE LOADER */
            ) : (
                <div className="calendar-react">
                    <CalendarNavigation 
                        time={{
                            lastYear: monthsContent[monthsContent.length - 1].year,
                            lastMonth: monthsContent[monthsContent.length - 1].month
                        }} 
                        onNewMonth={this.addMonth} 
                    />

                    <div className="calendar-content">
                        {monthsContent.map((month, key) => 
                            <CalendarItem time={month} isFirst={key === 0} key={key} />
                        )}
                    </div>

                    <div className="calendar-options">
                        <div className="calendar-buttons">
                            <CalendarSettings />

                            <button className="main-button calendar-option-button sub-button cal-holidays-btn" onClick={this.openYearHolidaysPopup}>
                                <span className="icon icon-small icon-holiday"></span>
                                <span className="cal-btn-text">Почивни дни 2024</span>
                            </button>
                            
                            {/* <button className="main-button calendar-option-button" onClick={this.openCalendarLegend}>
                                <span className="icon icon-small icon-legend"></span>
                                <span className="cal-btn-text">Легенда</span>
                            </button> */}
                        </div>

                        <CalendarFullScreen />
                    </div>

                    

                    {this.state.isActiveHolidaysPopup ? (
                        <SectionYearHolidays closeYearHolidaysPopup={this.closeYearHolidaysPopup} year={this.state.holidaysPopupActiveYear} holidays={yearHolidays["y" + this.state.holidaysPopupActiveYear]} />
                    ) : ""}

                    {/* {this.state.isActiveLegendPopup ? (
                        <div className="popup-wrap popup-calendar-legend" onClick={this.handleClickOutside}>
                            <div className="main-box popup-content">

                                <div className="legend-content">

                                </div>

                                <div className="close-button">
                                    <button className="close-btn" onClick={ this.closeCalendarLegend }>
                                        <div className="close-btn-arrow-left"></div>
                                        <div className="close-btn-arrow-right"></div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : ""} */}
                </div>
            )
        )
    }
}

const mapStateToProps = state => ({
    eventsByMonth: state.eventsByMonth,
    mainByMonth: state.mainByMonth
});

const mapDispatchToProps = dispatch => ({
    startLoadingEventsByMonth: (time) => dispatch(loadEventsByMonth(time)),
    startLoadingMainByMonth: (time) => dispatch(loadMainByMonth(time))
});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);