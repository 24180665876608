
import React, {Component} from 'react';
import Iframe from 'react-iframe';

class SectionSummary extends Component {
    render() {
        return (
            <section id="section-summary">
                <div className="section-title">
                    <h2>Какво е <span className="text-logo">Chronos</span> ?</h2>
                </div>
                <div className="summary-content">
                    <div className="summary-events">
                        <div className="summary-events-info">
                            <div className="summary-events-text">
                                <h3>Събитията около теб на едно <span className="text-orange">място</span>.</h3>
                                {/* <h3>Събитията около теб<span className="text-orange">.</span></h3>
                                <p>Платформа създадена да събере всички събития на едно място.</p> */}
                                {/* <p>Искаш ли да разбираш за всичко което се случва около теб?</p>
                                <p>Платформата се опитва да направи точно това. Следи всякакви събития, празници, именни дни и много други на едно място.</p>
                                Мрежата за събития. */}
                            </div>
                        </div>
                        <div className="summary-events-scene">
                            <div className="scene-holder appear-scroll">
                                <div className="scene">
                                    <div className="scene-screen">
                                        <Iframe 
                                            url="https://www.youtube.com/embed/or55UctPW4w?autoplay=1&mute=1&loop=1&controls=0&modestbranding=1&playlist=or55UctPW4w&autohide=1"
                                            className="event-map-iframe" 
                                            width="100%" 
                                            height="100%"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                            title="Chronos" />
                                    </div>
                                </div>
                            </div>
                            <div className="people-holder appear-scroll">
                                <div className="human-icon human-icon-1 human-pos-1"></div>
                                <div className="human-icon human-icon-2 human-pos-2"></div>
                                <div className="human-icon human-icon-3 human-pos-3"></div>
                                <div className="human-icon human-icon-4 human-pos-4"></div>
                                <div className="human-icon human-icon-5 human-pos-5"></div>
                                <div className="human-icon human-icon-6 human-pos-6"></div>
                                <div className="human-icon human-icon-7 human-pos-7"></div>
                                <div className="human-icon human-icon-8 human-pos-8"></div>
                                <div className="human-icon human-icon-1 human-pos-9"></div>
                                <div className="human-icon human-icon-2 human-pos-10"></div>
                                <div className="human-icon human-icon-3 human-pos-11"></div>
                                <div className="human-icon human-icon-4 human-pos-12"></div>
                                <div className="human-icon human-icon-5 human-pos-13"></div>
                                <div className="human-icon human-icon-6 human-pos-14"></div>
                                <div className="human-icon human-icon-7 human-pos-15"></div>
                                <div className="human-icon human-icon-8 human-pos-16"></div>
                                <div className="human-icon human-icon-1 human-pos-17"></div>
                                <div className="human-icon human-icon-2 human-pos-18"></div>
                                <div className="human-icon human-icon-3 human-pos-19"></div>
                                <div className="human-icon human-icon-4 human-pos-20"></div>
                                <div className="human-icon human-icon-5 human-pos-21"></div>
                                <div className="human-icon human-icon-6 human-pos-22"></div>
                                <div className="human-icon human-icon-7 human-pos-23"></div>
                                <div className="human-icon human-icon-8 human-pos-24"></div>
                                <div className="human-icon human-icon-1 human-pos-25"></div>
                                <div className="human-icon human-icon-2 human-pos-26"></div>
                                <div className="human-icon human-icon-3 human-pos-27"></div>
                                <div className="human-icon human-icon-4 human-pos-28"></div>
                                <div className="human-icon human-icon-5 human-pos-29"></div>
                                <div className="human-icon human-icon-6 human-pos-30"></div>
                                <div className="human-icon human-icon-7 human-pos-31"></div>
                                <div className="human-icon human-icon-8 human-pos-32"></div>
                                <div className="human-icon human-icon-1 human-pos-33"></div>
                                <div className="human-icon human-icon-2 human-pos-34"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SectionSummary;