import React from 'react';

const MoonState = (props) => {
    if (props?.moon.length === 0) {
        return "";
    }
    const moonAra = props.moon.split("-");
    let moonState = "";
    let moonText = "";

    if (moonAra[0] !== false && moonAra[0].length > 0) {
        moonState = moonAra[0].replace(/_/g, "-");
        moonText = (moonAra[0] === "full_moon") ? "Пълнолуние" : 
                    (moonAra[0] === "new_moon") ? "Новолуние" : 
                    (moonAra[0] === "first_quarter") ? "Първа четвъртина" : 
                    (moonAra[0] === "last_quarter") ? "Последна четвъртина" : "";
    }

    return (
        <div className="astro-moon box-astro-mini" title={moonText}>
            <span className={"icon-moon moon-" + moonState}></span>
            <span className="text">{moonAra[1]}</span>
        </div>
    )
}

export default MoonState;