import React, {Component} from 'react';
import CalendarSettingsPopup from './CalendarSettingsPopup';

class CalendarSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActiveSettingsPopup: false
        };
    }

    openSettingsPopup = () => {
        this.setState({isActiveSettingsPopup: true});
    }

    closeSettingsPopup = () => {
        this.setState({isActiveSettingsPopup: false});
    }

    render() {
        return (
            <>
                <button className="main-button calendar-option-button cal-settings-btn" onClick={this.openSettingsPopup}>
                    <span className="icon icon-small icon-settings"></span>
                    <span className="cal-btn-text">Настройки</span>
                </button>

                {this.state.isActiveSettingsPopup ? (
                    <CalendarSettingsPopup closeSettingsPopup={this.closeSettingsPopup} />
                ) : ""}
            </>
        )
    }
}

export default CalendarSettings;