import React from 'react';
import EventsItem from './EventsItem';
import {connect} from 'react-redux';

const EventsContent = ({ eventsUpcoming = []}) => (
    <div className="events-content">
        {eventsUpcoming.events.map((event, key) => (
            <EventsItem eventInfo={event} key={key} />
        ))}
    </div>
);

const mapStateToProps = state => ({
    eventsUpcoming: state.eventsUpcoming
});

export default connect(mapStateToProps)(EventsContent);