import React, {Component} from 'react';

class BackToTopButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backToTopActive: false
        }
    }

    componentDidMount() {
        document.addEventListener("scroll", this.onScrollHandleBackToTop, true);
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.onScrollHandleBackToTop);
    }

    scrollToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    onScrollHandleBackToTop = (e) => {
        let st = window.pageYOffset || e.srcElement.scrollTop;
        
        if (st > 144) {
            this.setState({
                backToTopActive: true
            });
        } else {
            this.setState({
                backToTopActive: false
            });
        }
    }

    render() {
        return (
            <div className={ this.state.backToTopActive ? "back-to-top-wrap back-to-top-active" : "back-to-top-wrap" }>
                <button onClick={ this.scrollToTop }></button>
            </div>
        ) 
    }
}

export default BackToTopButton;